import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap";
import { Card } from "react-bootstrap";
import classes from "./Dropdown.module.scss";
import arrowImg from "../../assets/images/icons/arrow-right.png";

/**
 * Description dropdown accordion used in single product page of B2C
 *
 * @author Aleksandar Ječmenić <aleksandar.jecmenic@croonus.com>
 */
const CustomToggle = ({ children, eventKey }) => {

  // Decorate accordion
  const decoratedOnClick = useAccordionButton(eventKey);

  return (
    <button
      type="button"
      className={classes["btn-accordion"]}
      onClick = {decoratedOnClick}
    >
      {children}
    </button>
  );
};

const Dropdown = ({ children, active, title, className }) => {
  return (
    <div className={`${classes["container"]} ${className}`}>
      <Accordion defaultActiveKey={active ? '0' : ''}>
        <Card className={classes["card"]}>
          <Card.Header className={classes["card-header"]}>
            <CustomToggle eventKey="0">
            <button className={classes["read-more"] + " button-primary"}>
            <span className={"button-span-1"}>
              {title}</span>
            <span className={"button-span-2 bs2-30"}>
              <span
                className={classes["read-more-arrow"] + " arrow-right-black"}
              >
                <img src={arrowImg} alt="arrow" />
              </span>
            </span>
          </button>
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body className={classes["card-body"]}>
              {children}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
};

export default Dropdown;
