import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import { Modal } from "react-bootstrap"
import CloseButton from "react-bootstrap/CloseButton"
import classes from "./SpecsModal.module.scss"
import arrowImg from "../../assets/images/icons/arrow-right.png"
import bp1Img from "../../assets/images/blueprints/bp-1.png"
import bp2Img from "../../assets/images/blueprints/bp-2.png"
import bp2Img2 from "../../assets/images/blueprints/bp-2-2.png"
import bp3Img from "../../assets/images/blueprints/bp-3.png"
import bp4Img from "../../assets/images/blueprints/bp-4.png"
import bp5Img from "../../assets/images/blueprints/bp-5.png"
//import bp5Img from "../../assets/images/blueprints/bp-5.png";
import { useNavigate } from "react-router-dom"
import Translated from "../../context/state"

const SpecsModal = ({ openModal, handleClose, activeTrailer }) => {
  const navigate = useNavigate()
  const close = () => handleClose()
  return (
    <Modal
      show={openModal}
      onHide={close}
      backdrop="static"
      keyboard={false}
      centered
      size="xl"
      scrollable={true}
      className={classes["card-preview-modal"] + " card-preview-modal"}
    >
      <CloseButton
        className={classes["close-button"]}
        aria-label="Hide"
        onClick={close}
      />
      <Modal.Body className={classes["body"]}>
        {
          activeTrailer === 1 ? (
            <div className={classes["modal-row"] + " row"}>
              <div className={classes["modal-row-left"] + " col-lg-5"}>
                <h2>
                <Translated Key="nooteboom_3" />
                  <br />
                  <span className={classes["modal-rect"] + " rect-secondary"} />
                </h2>
                <div className={classes["modal-paragraphs"]}>
                  <p>
                    <Translated Key="prikolica1" />
                  </p>
                </div>
                <button
                  className={classes["modal-button"] + " button-primary"}
                  onClick={() => {
                    navigate("/#VOQ")
                    handleClose()
                  }}
                >
                  <span className={"button-span-1"}>
                    <Translated Key="MODAL_order_transport" />
                  </span>
                  <span className={"button-span-2 bs2-35"}>
                    <span
                      className={classes["modal-arrow"] + " arrow-right-black"}
                    >
                      <img src={arrowImg} alt="arrow" />
                    </span>
                  </span>
                </button>
              </div>
              <div className={classes["modal-row-right"] + " col-lg-7"}>
                <img src={bp1Img} alt="blueprint" />
                <button
                  className={classes["modal-button-mobile"] + " button-primary"}
                  onClick={() => {
                    navigate("/#VOQ")
                    handleClose()
                  }}
                >
                  <span className={"button-span-1"}>
                    <Translated Key="MODAL_order_transport" />
                  </span>
                  <span className={"button-span-2 bs2-35"}>
                    <span
                      className={classes["modal-arrow"] + " arrow-right-black"}
                    >
                      <img src={arrowImg} alt="arrow" />
                    </span>
                  </span>
                </button>
              </div>
            </div>
          ) : activeTrailer === 2 ? (
            <div className={classes["modal-row"] + " row"}>
              <div className={classes["modal-row-left"] + " col-lg-5"}>
                <h2>
                <Translated Key="axle_platform" />
                  <br />
                  <span className={classes["modal-rect"] + " rect-secondary"} />
                  
                </h2>
                <div className={classes["modal-paragraphs"]}>
                  <p>
                    <Translated Key="prikolica2" />
                  </p>
                </div>
                <button
                  className={classes["modal-button"] + " button-primary"}
                  onClick={() => {
                    navigate("/#VOQ")
                    handleClose()
                  }}
                >
                  <span className={"button-span-1"}>
                    <Translated Key="MODAL_order_transport" />
                  </span>
                  <span className={"button-span-2 bs2-35"}>
                    <span
                      className={classes["modal-arrow"] + " arrow-right-black"}
                    >
                      <img src={arrowImg} alt="arrow" />
                    </span>
                  </span>
                </button>
              </div>
              <div className={classes["modal-row-right"] + " col-lg-7"}>
                <img src={bp2Img} alt="blueprint" />
                <button
                  className={classes["modal-button-mobile"] + " button-primary"}
                  onClick={() => {
                    navigate("/#VOQ")
                    handleClose()
                  }}
                >
                  <span className={"button-span-1"}>
                    <Translated Key="MODAL_order_transport" />
                  </span>
                  <span className={"button-span-2 bs2-35"}>
                    <span
                      className={classes["modal-arrow"] + " arrow-right-black"}
                    >
                      <img src={arrowImg} alt="arrow" />
                    </span>
                  </span>
                </button>
              </div>
            </div>
             ) : activeTrailer === 8 ? (
              <div className={classes["modal-row"] + " row"}>
                <div className={classes["modal-row-left"] + " col-lg-5"}>
                  <h2>
                  <Translated Key="axle_platform2" />
                    <br />
                    <span className={classes["modal-rect"] + " rect-secondary"} />
                    
                  </h2>
                  <div className={classes["modal-paragraphs"]}>
                    <p>
                      <Translated Key="prikolica2" />
                    </p>
                  </div>
                  <button
                    className={classes["modal-button"] + " button-primary"}
                    onClick={() => {
                      navigate("/#VOQ")
                      handleClose()
                    }}
                  >
                    <span className={"button-span-1"}>
                      <Translated Key="MODAL_order_transport" />
                    </span>
                    <span className={"button-span-2 bs2-35"}>
                      <span
                        className={classes["modal-arrow"] + " arrow-right-black"}
                      >
                        <img src={arrowImg} alt="arrow" />
                      </span>
                    </span>
                  </button>
                </div>
                <div className={classes["modal-row-right"] + " col-lg-7"}>
                  <img src={bp2Img2} alt="blueprint" />
                  <button
                    className={classes["modal-button-mobile"] + " button-primary"}
                    onClick={() => {
                      navigate("/#VOQ")
                      handleClose()
                    }}
                  >
                    <span className={"button-span-1"}>
                      <Translated Key="MODAL_order_transport" />
                    </span>
                    <span className={"button-span-2 bs2-35"}>
                      <span
                        className={classes["modal-arrow"] + " arrow-right-black"}
                      >
                        <img src={arrowImg} alt="arrow" />
                      </span>
                    </span>
                  </button>
                </div>
              </div>
          ) : activeTrailer === 3 ? (
            <div className={classes["modal-row"] + " row"}>
              <div className={classes["modal-row-left"] + " col-lg-5"}>
                <h2>
                  <Translated Key="low_rider" />
                  <br />
                  <span className={classes["modal-rect"] + " rect-secondary"} />
                  
                </h2>
                <div className={classes["modal-paragraphs"]}>
                  <p>
                    <Translated Key="prikolica3" />
                  </p>
                </div>
                <button
                  className={classes["modal-button"] + " button-primary"}
                  onClick={() => {
                    navigate("/#VOQ")
                    handleClose()
                  }}
                >
                  <span className={"button-span-1"}>
                    <Translated Key="MODAL_order_transport" />
                  </span>
                  <span className={"button-span-2 bs2-35"}>
                    <span
                      className={classes["modal-arrow"] + " arrow-right-black"}
                    >
                      <img src={arrowImg} alt="arrow" />
                    </span>
                  </span>
                </button>
              </div>
              <div className={classes["modal-row-right"] + " col-lg-7"}>
                <img src={bp3Img} alt="blueprint" />
                <button
                  className={classes["modal-button-mobile"] + " button-primary"}
                  onClick={() => {
                    navigate("/#VOQ")
                    handleClose()
                  }}
                >
                  <span className={"button-span-1"}>
                    <Translated Key="MODAL_order_transport" />
                  </span>
                  <span className={"button-span-2 bs2-35"}>
                    <span
                      className={classes["modal-arrow"] + " arrow-right-black"}
                    >
                      <img src={arrowImg} alt="arrow" />
                    </span>
                  </span>
                </button>
              </div>
            </div>
          ) : activeTrailer === 4 ? (
            <div className={classes["modal-row"] + " row"}>
              <div className={classes["modal-row-left"] + " col-lg-5"}>
                <h2>
                <Translated Key="nooteboom_4" />
                  <br />
                  <span className={classes["modal-rect"] + " rect-secondary"} />
                  
                </h2>
                <div className={classes["modal-paragraphs"]}>
                  <p>
                    <Translated Key="prikolica4" />
                  </p>
                </div>
                <button
                  className={classes["modal-button"] + " button-primary"}
                  onClick={() => {
                    navigate("/#VOQ")
                    handleClose()
                  }}
                >
                  <span className={"button-span-1"}>
                    <Translated Key="MODAL_order_transport" />
                  </span>
                  <span className={"button-span-2 bs2-35"}>
                    <span
                      className={classes["modal-arrow"] + " arrow-right-black"}
                    >
                      <img src={arrowImg} alt="arrow" />
                    </span>
                  </span>
                </button>
              </div>
              <div className={classes["modal-row-right"] + " col-lg-7"}>
                <img src={bp4Img} alt="blueprint" />
                <button
                  className={classes["modal-button-mobile"] + " button-primary"}
                  onClick={() => {
                    navigate("/#VOQ")
                    handleClose()
                  }}
                >
                  <span className={"button-span-1"}>
                    <Translated Key="MODAL_order_transport" />
                  </span>
                  <span className={"button-span-2 bs2-35"}>
                    <span
                      className={classes["modal-arrow"] + " arrow-right-black"}
                    >
                      <img src={arrowImg} alt="arrow" />
                    </span>
                  </span>
                </button>
              </div>
            </div>
          ) : (
            activeTrailer === 5 && (
              <div className={classes["modal-row"] + " row"}>
                <div className={classes["modal-row-left"] + " col-lg-5"}>
                  <h2>
                  <Translated Key="krone_mega_liner" />
                    <br />
                    <span
                      className={classes["modal-rect"] + " rect-secondary"}
                    />
                   
                  </h2>
                  <div className={classes["modal-paragraphs"]}>
                    <p>
                      <Translated Key="prikolica5" />
                    </p>
                  </div>
                  <button
                    className={classes["modal-button"] + " button-primary"}
                    onClick={() => {
                      navigate("/#VOQ")
                      handleClose()
                    }}
                  >
                    <span className={"button-span-1"}>
                      <Translated Key="MODAL_order_transport" />
                    </span>
                    <span className={"button-span-2 bs2-35"}>
                      <span
                        className={
                          classes["modal-arrow"] + " arrow-right-black"
                        }
                      >
                        <img src={arrowImg} alt="arrow" />
                      </span>
                    </span>
                  </button>
                </div>
                <div className={classes["modal-row-right"] + " col-lg-7"}>
                  <img src={bp5Img} alt="blueprint" />
                  <button
                    className={
                      classes["modal-button-mobile"] + " button-primary"
                    }
                    onClick={() => {
                      navigate("/#VOQ")
                      handleClose()
                    }}
                  >
                    <span className={"button-span-1"}>
                      <Translated Key="MODAL_order_transport" />
                    </span>
                    <span className={"button-span-2 bs2-35"}>
                      <span
                        className={
                          classes["modal-arrow"] + " arrow-right-black"
                        }
                      >
                        <img src={arrowImg} alt="arrow" />
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            )
          )
          //          : (
          //           <div className={classes["modal-row"] + " row"}>
          //             <div className={classes["modal-row-left"] + " col-lg-5"}>
          //               <h2>
          //                 KAESSBOHRER
          //                 <br />
          //                 <span className={classes["modal-rect"] + " rect-secondary"} />
          //                 K.SPA M 3 1N
          //               </h2>
          //               <div className={classes["modal-paragraphs"]}>
          //                 <p>
          //                   Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
          //                   fringilla, neque non auctor lacinia, nibh augue sollicitudin
          //                   neque, eu sagittis augue nibh id metus. Sed ultricies metus
          //                   erat, ut efficitur nunc rhoncus non. Aliquam ornare venenatis
          //                   augue at facilisis. Sed finibus, elit sit amet venenatis
          //                   vestibulum, mauris purus ultrices dui, sed scelerisque justo
          //                   quam sed diam.
          //                 </p>

          //               </div>
          //               <button
          //                 className={classes["modal-button"] + " button-primary"}
          //                 onClick={() => {
          //                   navigate("/#VOQ");
          //                   handleClose();
          //                 }}
          //               >
          //                 <span className={"button-span-1"}><Translated Key="MODAL_order_transport"/></span>
          //                 <span className={"button-span-2 bs2-35"}>
          //                   <span
          //                     className={classes["modal-arrow"] + " arrow-right-black"}
          //                   >
          //                     <img src={arrowImg} alt="arrow" />
          //                   </span>
          //                 </span>
          //               </button>
          //             </div>
          //             <div className={classes["modal-row-right"] + " col-lg-7"}>
          //               <img src={bp5Img} alt="blueprint" />
          // <button
          //                 className={classes["modal-button-mobile"] + " button-primary"}
          //                 onClick={() => {
          //                   navigate("/#VOQ");
          //                   handleClose();
          //                 }}
          //               >
          //                 <span className={"button-span-1"}><Translated Key="MODAL_order_transport"/></span>
          //                 <span className={"button-span-2 bs2-35"}>
          //                   <span
          //                     className={classes["modal-arrow"] + " arrow-right-black"}
          //                   >
          //                     <img src={arrowImg} alt="arrow" />
          //                   </span>
          //                 </span>
          //               </button>
          //             </div>
          //           </div>
          //         )
        }
      </Modal.Body>
    </Modal>
  )
}

export default SpecsModal
