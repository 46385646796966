import React from "react";
import GoogleMapReact from "google-map-react";
import { theme } from "./MapStyle";
import pin from "../assets/images/icons/location-pin.png";
import classes from "../components/layout/Footer.module.scss";
import { useState } from "react";
const Marker = ({ active, city }) => (
  <div className={"relative " + classes["map-marker"]}>
    <img src={pin} style={{ height: "40px" }} />
    <div
      className={
        classes["footer-top-right-cloud"] +
        (active ? " " + classes["footer-top-right-cloud-active"] : "")
      }
    >
      {city === "cacak" ? (
        <>
          <h2 className={classes["footer-top-left-heading"]}>
            <span className={classes["footer-rect"] + " rect-secondary"}></span>
            <span>Čačak</span>
          </h2>
          <p>
            <span>Gornji Prijevorski put 33</span>
            <span>32205 Trbušani, Čačak, Srbija</span>
            <span>+381 32 322-322;</span>
            <span>+381 32 342-687</span>
            <span>Fax: +381 32 342-530</span>
          </p>
        </>
      ) : city === "kragujevac" ? (
        <>
          <h2 className={classes["footer-top-left-heading"]}>
            <span className={classes["footer-rect"] + " rect-secondary"}></span>
            <span>Kragujevac</span>
          </h2>
          <p>
            <span>Aleja Milanović bb</span>
            <span>34325 Mind Park, Srbija</span>
            <span>+381 32 322-322;</span>
            <span>+381 32 342-687</span>
            <span>Fax: +381 32 342-530</span>
          </p>
        </>
      ) : city === "beograd" ? (
        <>
          <h2 className={classes["footer-top-left-heading"]}>
            <span className={classes["footer-rect"] + " rect-secondary"}></span>
            <span>Beograd</span>
          </h2>
          <p>
            <span>Bulevar Vudroa Vilsona 8v/903</span>
            <span>11070 Beograd, Srbija</span>
            <span>+381 32 322-322;</span>
            <span>+381 32 342-687</span>
            <span>Fax: +381 32 342-530</span>
          </p>
        </>
      ) : (
        <>
          <h2 className={classes["footer-top-left-heading"]}>
            <span className={classes["footer-rect"] + " rect-secondary"}></span>
            <span>Ljubljana</span>
          </h2>
          <p>
            <span>Letališka cesta 32j</span>
            <span>1000 Ljubljana, Slovenija</span>
            <span>+386 593 65821</span>
            <span>+386 593 65822</span>
            <span>+386 593 67250</span>
          </p>
        </>
      )}
    </div>
  </div>
);

const distanceToMouse = (pt, mp) => {
  if (pt && mp) {
    // return distance between the marker and mouse pointer
    return Math.sqrt(
      (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
    );
  }
};
const MyGoogleMap = ({ latValue, lngValue }) => {
  const center = {
    lat: latValue,
    lng: lngValue,
  };
  const [newCenter, setNewCenter] = useState(center);
  const zoom = 7;
  const [active, setActive] = useState(-1);
  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "100%", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAb3yABiMy-kIRMSGFD1YQMMp8pMHPZ2m0" }}
        defaultCenter={center}
        defaultZoom={zoom}
        center={newCenter}
        zoomControl={false}
        distanceToMouse={distanceToMouse}
        options={{ styles: theme }}
        yesIWantToUseGoogleMapApiInternals={true}
        onChildClick={(key, childProps) => {
          if (childProps.number === 0 && active !== 0) setActive(0);
          else if (childProps.number === 1 && active !== 1) setActive(1);
          else if (childProps.number === 2 && active !== 2) setActive(2);
          else if (childProps.number === 3 && active !== 3) setActive(3);
          else {
            setActive(-1);
          }
          //setNewCenter({lat: childProps.lat, lng: childProps.lng});
        }}
      >
        <Marker
          lat={43.92478996580695}
          lng={20.30798079833704}
          number={0}
          active={active === 0 ? true : false}
          city="cacak"
        />
        <Marker
          lat={44.80499868893121}
          lng={20.409965023168493}
          number={1}
          active={active === 1 ? true : false}
          city="beograd"
        />
        <Marker
          lat={44.12274451491161}
          lng={20.854701540692993}
          number={2}
          active={active === 2 ? true : false}
          city="kragujevac"
        />
        <Marker
          lat={46.06277477086082}
          lng={14.572518117223556}
          number={3}
          active={active === 3 ? true : false}
          city="ljubljana"
        />
        {/* <Marker lat={44.41570117983443} lng={16.706449647309284} /> */}
      </GoogleMapReact>
    </div>
  );
};

export default MyGoogleMap;
