import React, { useState } from "react";
import styled from "styled-components";
import classes from "./Header";
import globeImg from "../../assets/images/icons/globe.png";
import { useAppContext } from "../../context/state";
const Main = styled("div")`
  cursor: pointer;
  height: 40px;
  width: 200px;
`;

const DropDownContainer = styled("div")`
  width: 100%;
  position: relative;
  margin: 0 auto;
  button {
    width: 100%;
    height: 40px;
    img {
      height: 100%;
    }
    & > span {
      &:last-child {
        padding: 0.5rem 0.25rem;
      }
    }
  }
`;

const DropDownHeader = styled("div")`
  margin-bottom: 0.8em;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-weight: 400;
  text-align: center;
  font-size: 1.125rem;
  color: var(--croonus-black);
  background: #ffffff;
`;

const DropDownListContainer = styled("div")``;

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  border: 2px solid var(--secondary-color);
  border-radius: 10px;
  z-index: 10;
  width: 100%;
  top: calc(100% - 3px);
  position: absolute;
  background: #ffffff;
  color: var(--croonus-black);
  font-size: 1.125rem;
  font-weight: 400;
  overflow:hidden;
`;

const ListItem = styled("li")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  padding: 0.5rem 0;
  padding-left: 1rem;
  transition: all 0.3s ease-in-out;
  position: relative;
  color: var(--croonus-black);
  background-color: var(--croonus-white);
  &::after {
    content:'';
    position: absolute;
    right: 0;
    top: 0;
    width:50px;
    height:100%;
    background-color: var(--secondary-color);
    transform: translateX(100%);
    transition: all 0.3s ease-in-out;
  }
  &::before {
    position: absolute;
    content: '';
    width:0px;
    height:0px;
    border: 50px solid transparent;
    border-bottom-color: var(--secondary-color);
    top:-50px;
    right:0;
    pointer-events:none;
    transform: translateX(100%);
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    &::after {
      transform: translateX(0);
      transition: all 0.2s ease-in-out;
    }
    &::before {
      transform: translateX(0);
      transition: all 0.2s ease-in-out;
    }
  }
  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    &::after {
      border-top-right-radius: 5px;
    }
  }
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    &::after {
      border-bottom-right-radius: 5px;
    }
  }
  
  &:not(:last-child) {
    border-bottom: 1px solid var(--secondary-color);
  }
`;

const LanguageSelector = ({ options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [language, setLanguage] = useAppContext();
  const [selectedOption, setSelectedOption] = useState(language === 'en-us' ? "English" : language === "de_de" ? "Deutsch" : "Srpski");

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (value) => () => {
    setSelectedOption(value);
    switch (value) {
      case "English":
        setLanguage('en_us');
        break;
      case "Srpski":
        setLanguage('sr_rs');
        break;
        case "Deutsch":
        setLanguage('de_de');
        break;
    }
    setIsOpen(false);
  };

  return (
    <Main>
      <DropDownContainer>
        <DropDownHeader onClick={toggling}>
          <button className={classes["language-button"] + " button-primary"}>
            <span className={"button-span-1"}>
              {language === 'en_us' ? "English" : language === "de_de" ? "Deutsch" : "Srpski" }
            </span>
            <span className={classes["globe-span"] + " button-span-2 bs2-40"}>
              <img src={globeImg} alt="earth" />
            </span>
          </button>
        </DropDownHeader>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
              {options.map((option) => (
                <ListItem onClick={onOptionClicked(option)} key={Math.random()}>
                  {option}
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </Main>
  );
};
export default LanguageSelector;
