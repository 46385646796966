/**
 * Login page of B2C.
 *
 * @author Aleksandar Ječmenić <jecmenic.aleksandar@croonus.com>
*/
const LoginPage = () => {
    return (
        <p>LoginPage</p>
    );
}

export default LoginPage;