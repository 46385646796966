import { useEffect, useState } from "react"
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import Footer from "./components/layout/Footer"
import HomePage from "./pages/HomePage"
import LoginPage from "./pages/LoginPage"
import NotFoundPage from "./pages/NotFoundPage"
import ReferencePage from "./pages/ReferencePage"
import Header from "./components/layout/Header"
import AboutUsPage from "./pages/AbousUsPage"
import TermsOfUsePage from "./pages/TermsOfUsePage"
import ContactPage from "./pages/ContactPage"
import ServicesPage from "./pages/ServicesPage"
import CaseStudyPage from "./pages/CaseStudyPage"
import BlogPage from "./pages/BlogPage"
import BlogPostPage from "./pages/BlogPostPage"
import { useScrollPosition, useWindowDimensions } from "./helpers/functions"
import IMSPage from "./pages/IMSPage"
import { useAppContext } from "./context/state"
import CookiePop from "./components/UI/CookiePop"
import InformationSecurity from "./pages/InformationSecurity"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(true)
  const location = useLocation()
  const scroll = useScrollPosition()
  const [lang, setLang, data] = useAppContext()
  const [showSticky, setShowSticky] = useState(false)
  const { height, width } = useWindowDimensions()
  const [cookiesAllowed, setCookiesAllowed] = useState(
    localStorage.getItem("cookiesAllowed")
  )
  useEffect(() => {
    scroll > 100 || width < 991.98 ? setShowSticky(true) : setShowSticky(false)
  }, [scroll, width])

  //GET TO HASH ELEMENT
  const { pathname, hash, key } = useLocation()

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0)
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "")
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView()
        }
      }, 0)
    }
  }, [pathname, hash, key]) // do this on route change
  return (
    <div className="App">
      <GoogleReCaptchaProvider
        reCaptchaKey={
          process.env.REACT_APP_SITE_KEY ||
          "6LcDgsYcAAAAAKqPjxDLw6kLcoimMLTGZfjIMu67"
        }
      >
        <Header
          sticky={showSticky}
          className={location.pathname === "/" ? "header-homepage" : ""}
          page="home-page"
          navBarClass={location.pathname === "/" ? "header-homepage-color" : ""}
        />
        <Routes>
          {!isLoggedIn && (
            <>
              <Route path="" exact element={<Navigate replace to="/login" />} />
              <Route path="/login" element={<LoginPage />} />
            </>
          )}
          {isLoggedIn && <></>}
          <Route path="" element={<HomePage />} />
          <Route path="/reference" element={<ReferencePage />} />
          <Route path="/o-nama" element={<AboutUsPage />} />
          <Route path="/uslovi" element={<TermsOfUsePage />} />
          <Route path="/kontakt" element={<ContactPage />} />
          <Route path="/usluge" element={<ServicesPage service={3} />} />
          <Route
            path="/medjunarodne-selidbe"
            element={<ServicesPage service={0} />}
          />
          <Route
            path="/prevoz-tekstila"
            element={<ServicesPage service={1} />}
          />
          <Route
            path="/frigo-transport"
            element={<ServicesPage service={2} />}
          />
          <Route path="/adr-transport" element={<ServicesPage service={3} />} />
          <Route path="/vangabaritni" element={<ServicesPage service={4} />} />
          <Route
            path="/carinsko-zastupanje"
            element={<ServicesPage service={5} />}
          />
          <Route
            path="/komercijalnih-roba"
            element={<ServicesPage service={6} />}
          />
          <Route
            path="/kontejnerski-transport"
            element={<ServicesPage service={7} />}
          />
          <Route path="/case-study" element={<CaseStudyPage />} />
          <Route path="/aktuelno" element={<BlogPage />} />
          <Route path="/aktuelno/:blogId" element={<BlogPostPage />} />
          <Route path="/uslovi" element={<TermsOfUsePage />} />
          <Route path="/politika-ims" element={<IMSPage />} />
          <Route
            path="/information-security"
            element={<InformationSecurity />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>

        <Footer />

        <ToastContainer theme="colored" position="top-right" />
        {!cookiesAllowed && (
          <CookiePop
            handleAccept={() => {
              setCookiesAllowed(true)
              localStorage.setItem("cookiesAllowed", true)
            }}
          />
        )}
      </GoogleReCaptchaProvider>
    </div>
  )
}

export default App
