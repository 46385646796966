import classes from "../assets/scss/TermsOfUsePage.module.scss"
import Header2 from "../components/layout/Header2"
import Translated from "../context/state"
const IMSPage = () => {
  return (
    <>
      <Header2 title={`IMS_Politics`} />
      <div className={classes["container"]}>
        <h2>
          <Translated Key="IMS_Heading1" />
          <br />
          <span className={classes["terms-rect"] + " rect-secondary"}></span>
          <Translated Key="IMS_Heading2" />
        </h2>
        <div className={classes["content"]}>
          <h4>
            <span id="politika" className={classes["title-divider"]}></span>
            <span
              className={classes["terms-small-rect"] + " rect-secondary"}
            ></span>
            <Translated Key="IMS_SubHeading" />
          </h4>
          <div className={classes["paragraphs"]}>
            <p>
              <Translated Key="IMS_p1" />
            </p>
            <ul>
              <li>
                <Translated Key="IMS_list_item_1" />
              </li>
              <li>
                <Translated Key="IMS_list_item_2" />
              </li>
              <li>
                <Translated Key="IMS_list_item_3" />
              </li>
              <li>
                <Translated Key="IMS_list_item_4" />
              </li>
              <li>
                <Translated Key="IMS_list_item_5" />
              </li>
              <li>
                <Translated Key="IMS_list_item_6" />
              </li>
              <li>
                <Translated Key="IMS_list_item_7" />
              </li>
              <li>
                <Translated Key="IMS_list_item_8" />
              </li>
              <li>
                <Translated Key="IMS_list_item_9" />
              </li>
              <li>
                <Translated Key="IMS_list_item_10" />
              </li>
              <li>
                <Translated Key="IMS_list_item_11" />
              </li>
              <li>
                <Translated Key="IMS_list_item_12" />
              </li>
              <li>
                <Translated Key="IMS_list_item_13" />
              </li>
              <li>
                <Translated Key="IMS_list_item_14" />
              </li>
              <li>
                <Translated Key="IMS_list_item_15" />
              </li>
              <li>
                <Translated Key="IMS_list_item_16" />
              </li>
            </ul>
            <p>
              <Translated Key="IMS_p2" />
            </p>
            <p>
              <Translated Key="IMS_p3" />
            </p>
            <p>
              <Translated Key="IMS_p4" />
            </p>
            <p>
              <Translated Key="IMS_p5" />
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default IMSPage
