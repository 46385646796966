import classes from "./BlogPostBox.module.scss";
import boatImg from "../../assets/images/banners/boat.jpeg";
import arrowImg from "../../assets/images/icons/arrow-right.png";
import { useNavigate } from "react-router-dom";
import Translated from "../../context/state";

const BlogPostBox = ({
  title = "",
  description = "",
  image = "",
  id = "0",
}) => {
  const navigate = useNavigate();
  return (
    <div className={classes["container"]}>
      <div className={classes["post-image"]}>
        <img src={image} alt="post-img" />
        <div className={classes["gray-overlay"]}></div>
      </div>
      <div className={classes["floating-div-container"]}>
        <div className={classes["floating-div"]}>
          <h4>
            <span className={classes["floating-rect"] + " rect-secondary"} />
            <Translated Key={title} parsed />
          </h4>
          <p>
            <Translated Key={description} parsed />
          </p>
          <button
            className={classes["read-more"] + " button-secondary"}
            onClick={() => navigate(id)}
          >
            <span className={"button-span-1"}>
              {" "}
              <Translated Key="blogpost_read_more" parsed />{" "}
            </span>
            <span className={"button-span-2 bs2-35"}>
              <span
                className={classes["read-more-arrow"] + " arrow-right-black"}
              >
                <img src={arrowImg} alt="arrow" />
              </span>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlogPostBox;
