import React, { useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./Slider1.scss"
import arrowImg from "../../assets/images/icons/arrow-right.png"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useWindowDimensions } from "../../helpers/functions"

/**
 * Slider for product images on Single product page of B2B
 *
 * @author Aleksandar Ječmenić <aleksandar.jecmenic@croonus.com>
 */
function Arrow(props) {
  let className = props.type === "next" ? "nextArrow" : "prevArrow"
  className += " arrow"
  const char =
    props.type === "next" ? (
      <span className={"slick-arrow-circle" + " arrow-right-black"}>
        <img src={arrowImg} alt="arrow" />
      </span>
    ) : (
      <span className={"slick-arrow-circle" + " arrow-left-black"}>
        <img src={arrowImg} alt="arrow" />
      </span>
    )
  return (
    <span className={className} onClick={props.onClick}>
      {char}
    </span>
  )
}
function Arrow2(props) {
  let className = props.type === "next" ? "nextArrow" : "prevArrow"
  className += " arrow"
  const char =
    props.type === "next" ? (
      <FontAwesomeIcon className="slick-next-arrow" icon={faChevronRight} />
    ) : (
      <FontAwesomeIcon className="slick-prev-arrow" icon={faChevronLeft} />
    )
  return (
    <span className={className} onClick={props.onClick}>
      {char}
    </span>
  )
}

const Slider1 = (props) => {
  const [nav1, setNav1] = useState()
  const [nav2, setNav2] = useState()
  const [numberOfImages, setNumberOfImages] = useState(5)
  const { height, width } = useWindowDimensions()
  return (
    <>
      <div className="container col-12 product-details-slider-container">
        <div className="row flex-start">
          <div className="col-xl-9">
            <Slider
              asNavFor={nav1}
              ref={(slider2) => setNav2(slider2)}
              focusOnSelect={false}
              infinite={true}
              draggable={true}
              vertical={false}
              arrows={false}
              nextArrow={<Arrow2 type="next" />}
              prevArrow={<Arrow2 type="prev" />}
              dots={false}
              className={"big-slick-slider"}
            >
              {props.images !== undefined
                ? props.images.map((image, index) => {
                    return (
                      <div className="slick-small-img-div" key={index}>
                        <img className="slick-small-img" alt="" src={image} />
                      </div>
                    )
                  })
                : ""}
            </Slider>
            {props.children}
          </div>
          <div className="col-xl-3 slick-flex-down">
            <div className="col-10">
              <Slider
                asNavFor={nav2}
                ref={(slider1) => setNav1(slider1)}
                focusOnSelect={true}
                centerPadding="50px"
                vertical={width > 1198.98 ? true : false}
                infinite={true}
                verticalSwiping={width > 1198.98 ? true : false}
                dots={false}
                arrows={width > 1198.98 ? true : false}
                nextArrow={<Arrow type="next" />}
                prevArrow={<Arrow type="prev" />}
                slidesToShow={3}
                className={"vertical-slider"}
              >
                {props.images !== undefined
                  ? props.images.map((image, index) => {
                      return (
                        <div className="slick-small-img-div" key={index}>
                          <img className="slick-small-img" alt="" src={image} />
                        </div>
                      )
                    })
                  : ""}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Slider1
