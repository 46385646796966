import { Fragment } from 'react'
import classes from '../assets/scss/ServicesPage.module.scss'
import Header2 from '../components/layout/Header2'
import boatImg from '../assets/images/banners/boat.jpeg'
import frigo35 from '../assets/images/servicesTrucks/kombiFrigo35.png'
import furgon35 from '../assets/images/servicesTrucks/kombiFurgon35.png'
import frigo75 from '../assets/images/servicesTrucks/kamionFrigo75.png'
import cerada35 from '../assets/images/servicesTrucks/kombiCerada35.png'
import cerada75 from '../assets/images/servicesTrucks/kamionCerada75.png'
import cerada125 from '../assets/images/servicesTrucks/kamionCerada125.png'
import mega from '../assets/images/servicesTrucks/vangabarit3.png'
import arrowImg from '../assets/images/icons/arrow-right.png'
import Dropdown from '../components/UI/Dropdown'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Translated from '../context/state'
import borderCheckImg from '../assets/images/banners/border-check.png'

import gdp from '../assets/images/gdp/gdp.webp'
//icons

import tekstil from '../assets/images/icons/thread.png'
import frigo from '../assets/images/icons/frozen-food.png'
import avio from '../assets/images/icons/flight.png'
import adr from '../assets/images/icons/pills.png'
import globe from '../assets/images/icons/globe.png'
import komerc from '../assets/images/icons/fast.png'
import carine from '../assets/images/icons/packages.png'
import vangabarit from '../assets/images/icons/overload.png'

const ServicesPage = ({ service }) => {
  const navigate = useNavigate()
  const [serviceNumber, setServiceNumber] = useState(service)
  const [selidbeTruck, setSelidbeTruck] = useState(0)
  const [tekstilTruck, setTekstilTruck] = useState(0)
  const [frigoTruck, setFrigoTruck] = useState(1)
  const [adrTruck, setAdrTruck] = useState(0)
  const [vangabaritniTruck, setVangabaritniTruck] = useState(0)
  /*  const [carinskoTruck, setCarinskoTruck] = useState(0) */
  const [komercijalnoTruck, setKomercijalnoTruck] = useState(0)
  /*  const [avioTruck, setAvioTruck] = useState(0) */
  //   selidbe: 0, // 2
  //   tekstil:0, // 3
  //   frigo:0, // 6
  //   adr:0, // 6
  //   vangabaritni:0, // 1
  //   carinsko:0, //
  //   komercijanlno:0, // 3
  //   avio:0, //
  // })
  /* const animationStyle = () => ({
    transform: "translateX(-50vw)",
  }) */

  useEffect(() => {
    setServiceNumber(service)
  }, [service])

  let nextImage = false,
    prevImage = false,
    nextText = false,
    prevText = false,
    nextPage = '',
    prevPage = ''
  switch (serviceNumber) {
    case 0:
      nextImage = tekstil
      nextText = (
        <Fragment>
          <Translated Key='SERVICES_PAGE_transportation_of_textiles' />
          <br />
          <b>
            <Translated Key='SERVICES_PAGE_transportation_of_textiles2' />
          </b>
        </Fragment>
      )
      nextPage = '/prevoz-tekstila'
      prevPage = ''
      break
    case 1:
      prevImage = globe
      prevText = (
        <Fragment>
          <Translated Key='SERVICES_PAGE_international_removals' />
          <br />
          <b>
            <Translated Key='SERVICES_PAGE_international_removals2' />
          </b>
        </Fragment>
      )
      nextImage = frigo
      nextText = (
        <Fragment>
          <Translated Key='SERVICES_PAGE_frigo_transport' />
          <br />
          <b>
            <Translated Key='SERVICES_PAGE_frigo_transport2' />
          </b>
        </Fragment>
      )

      prevPage = '/medjunarodne-selidbe'
      nextPage = '/frigo-transport'

      break
    case 2:
      prevImage = tekstil
      prevText = (
        <Fragment>
          <Translated Key='SERVICES_PAGE_transportation_of_textiles' />
          <br />
          <b>
            <Translated Key='SERVICES_PAGE_transportation_of_textiles2' />
          </b>
        </Fragment>
      )
      nextImage = adr
      nextText = (
        <Fragment>
          <Translated Key='SERVICES_PAGE_adr_transport' />
          <br />
          <b>
            <Translated Key='SERVICES_PAGE_adr_transport2' />
          </b>
        </Fragment>
      )
      prevPage = '/prevoz-tekstila'
      nextPage = '/adr-transport'

      break
    case 3:
      prevImage = frigo
      prevText = (
        <Fragment>
          <Translated Key='SERVICES_PAGE_frigo_transport' />
          <br />
          <b>
            <Translated Key='SERVICES_PAGE_frigo_transport2' />
          </b>
        </Fragment>
      )
      nextImage = vangabarit
      nextText = (
        <Fragment>
          <Translated Key='SERVICES_PAGE_oversized_transport' />
          <br />
          <b>
            <Translated Key='SERVICES_PAGE_oversized_transport2' />
          </b>
        </Fragment>
      )

      prevPage = '/frigo-transport'
      nextPage = '/vangabaritni'

      break
    case 4:
      prevImage = adr
      prevText = (
        <Fragment>
          <Translated Key='SERVICES_PAGE_adr_transport' />
          <br />
          <b>
            <Translated Key='SERVICES_PAGE_adr_transport2' />
          </b>
        </Fragment>
      )
      nextImage = carine
      nextText = (
        <Fragment>
          <Translated Key='SERVICES_PAGE_customs_representation' />
          <br />
          <b>
            <Translated Key='SERVICES_PAGE_customs_representation2' />
          </b>
        </Fragment>
      )
      prevPage = '/adr-transport'
      nextPage = '/carinsko-zastupanje'

      break
    case 5:
      prevImage = vangabarit
      prevText = (
        <Fragment>
          <Translated Key='SERVICES_PAGE_oversized_transport' />
          <br />
          <b>
            <Translated Key='SERVICES_PAGE_oversized_transport2' />
          </b>
        </Fragment>
      )
      nextImage = komerc
      nextText = (
        <Fragment>
          <Translated Key='SERVICES_PAGE_transport_of_commercial_goods' />
          <br />
          <b>
            <Translated Key='SERVICES_PAGE_transport_of_commercial_goods2' />
          </b>
        </Fragment>
      )
      prevPage = '/vangabaritni'
      nextPage = '/komercijalnih-roba'

      break
    case 6:
      prevImage = carine
      prevText = (
        <Fragment>
          <Translated Key='SERVICES_PAGE_customs_representation' />
          <br />
          <b>
            <Translated Key='SERVICES_PAGE_customs_representation2' />
          </b>
        </Fragment>
      )
      nextImage = avio
      nextText = (
        <Fragment>
          <Translated Key='SERVICES_PAGE_container_avio_transport' />
          <br />
          <b>
            <Translated Key='SERVICES_PAGE_container_avio_transport2' />
          </b>
        </Fragment>
      )
      prevPage = '/carinsko-zastupanje'
      nextPage = '/kontejnerski-transport'

      break
    case 7:
      prevImage = komerc
      prevText = (
        <Fragment>
          <Translated Key='SERVICES_PAGE_transport_of_commercial_goods' />
          <br />
          <b>
            <Translated Key='SERVICES_PAGE_transport_of_commercial_goods2' />
          </b>
        </Fragment>
      )

      nextPage = ''
      prevPage = '/komercijalnih-roba'

      break
    default:
      break
  }
  return (
    <>
      <Header2 title={'HEADER_services'} />
      <div className={classes['container']}>
        <div className={classes['top-buttons']}>
          {serviceNumber !== 0 && (
            <button
              className={'previous-button'}
              onClick={() => {
                navigate(prevPage)
                /* setServiceNumber((prev) => {
                  return prev - 1
                }) */
              }}
            >
              <span className={'previous-button-span-left'}>
                <img src={arrowImg} alt='arrow' />
              </span>
              <span className={'previous-button-span-right'}>
                <span className={'previous-button-span-right-1'}>
                  {prevText}
                </span>
              </span>
              <img src={prevImage} alt='prev-icon' />
            </button>
          )}

          {+serviceNumber === 0 && (
            <h2>
              <Translated Key='SERVICES_PAGE_international_removals' />
              <br />
              <span
                className={classes['top-buttons-rect'] + ' rect-secondary'}
              />
              <Translated Key='SERVICES_PAGE_international_removals2' />.
            </h2>
          )}
          {+serviceNumber === 1 && (
            <h2>
              <Translated Key='SERVICES_PAGE_transportation_of_textiles' />
              <br />
              <span
                className={classes['top-buttons-rect'] + ' rect-secondary'}
              />
              <Translated Key='SERVICES_PAGE_transportation_of_textiles2' />.
            </h2>
          )}
          {+serviceNumber === 2 && (
            <h2>
              <Translated Key='SERVICES_PAGE_frigo_transport' />
              <br />
              <span
                className={classes['top-buttons-rect'] + ' rect-secondary'}
              />
              <Translated Key='SERVICES_PAGE_frigo_transport2' />.
            </h2>
          )}
          {+serviceNumber === 3 && (
            <h2>
              <Translated Key='SERVICES_PAGE_adr_transport' />
              <br />
              <span
                className={classes['top-buttons-rect'] + ' rect-secondary'}
              />
              <Translated Key='SERVICES_PAGE_adr_transport2' />.
            </h2>
          )}
          {+serviceNumber === 4 && (
            <h2>
              <Translated Key='SERVICES_PAGE_oversized_transport' />
              <br />
              <span
                className={classes['top-buttons-rect'] + ' rect-secondary'}
              />
              <Translated Key='SERVICES_PAGE_oversized_transport2' />.
            </h2>
          )}
          {+serviceNumber === 5 && (
            <h2>
              <Translated Key='SERVICES_PAGE_customs_representation' />
              <br />
              <span
                className={classes['top-buttons-rect'] + ' rect-secondary'}
              />
              <Translated Key='SERVICES_PAGE_customs_representation2' />.
            </h2>
          )}
          {+serviceNumber === 6 && (
            <h2>
              <Translated Key='SERVICES_PAGE_transport_of_commercial_goods' />
              <br />
              <span
                className={classes['top-buttons-rect'] + ' rect-secondary'}
              />
              <Translated Key='SERVICES_PAGE_transport_of_commercial_goods2' />.
            </h2>
          )}
          {+serviceNumber === 7 && (
            <h2>
              <Translated Key='SERVICES_PAGE_container_avio_transport' />
              <br />
              <span
                className={classes['top-buttons-rect'] + ' rect-secondary'}
              />
              <Translated Key='SERVICES_PAGE_container_avio_transport2' />.
            </h2>
          )}
          {+serviceNumber !== 7 && (
            <button
              className={'next-button'}
              onClick={() => {
                navigate(nextPage)
                /* setServiceNumber((prev) => {
                  return prev + 1
                }) */
              }}
            >
              {' '}
              <img src={nextImage} alt='next-icon' />
              <span className={'next-button-span-left'}>
                <span className={'next-button-span-left-1'}>{nextText}</span>
              </span>{' '}
              <span className={'next-button-span-right'}>
                <img src={arrowImg} alt='arrow' />
              </span>
            </button>
          )}
        </div>
        <div
          className={classes['custom-slider']}
          style={{ transform: `translateX(-${100 * +serviceNumber}vw)` }}
        >
          {/*0*/}
          <div className={classes['custom-slider-slide']}>
            <div className={classes['custom-slider-slide-content']}>
              <div className={classes['middle-row'] + ' row'}>
                <div className={classes['middle-row-left'] + ' col-xl-4'}>
                  <h4 className={classes['middle-row-left-sub-heading']}>
                    <span
                      className={
                        classes['sub-heading-rect'] + ' rect-secondary'
                      }
                    />
                    <Translated Key='SERVICES_PAGE_international_removals_subtitle' />
                  </h4>
                  <p className={classes['middle-row-left-p-2']}>
                    <Translated Key='SERVICES_PAGE_international_removals_text_p1' />
                  </p>
                  <p className={classes['middle-row-left-p-2']}>
                    <Translated Key='SERVICES_PAGE_international_removals_text_p2' />
                  </p>
                </div>
                <div className={classes['middle-row-right'] + ' col-xl-8'}>
                  {/* SELIDBA TRUCKS */}
                  <div className={'row'}>
                    {selidbeTruck === 0 ? (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img src={furgon35} alt='truck3' />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='SERVICES_PAGE_vehicle_van_3.5t' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_length' />
                                </span>
                                <span>4.2m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_width' />
                                </span>
                                <span>2.2m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_height' />
                                </span>
                                <span>2.3m</span>
                              </span>
                            </div>

                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_max_number_pallet_places' />{' '}
                              9
                            </span>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() => setSelidbeTruck(3)}
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() => setSelidbeTruck(1)}
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : selidbeTruck === 1 ? (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img src={frigo75} alt='truck3' />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='SERVICES_PAGE_vehicle_solo_truck_furgon_7.5t' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_length' />
                                </span>
                                <span>6m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_width' />
                                </span>
                                <span>2.4m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_height' />
                                </span>
                                <span>2.7m</span>
                              </span>
                            </div>

                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_max_number_pallet_places' />{' '}
                              15
                            </span>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() => setSelidbeTruck(0)}
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() => setSelidbeTruck(2)}
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : selidbeTruck === 2 ? (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img src={cerada75} alt='truck3' />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='SERVICES_PAGE_vehicle_solo_truck_tarpaulin_7.5t' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_length' />
                                </span>
                                <span>6.2m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_width' />
                                </span>
                                <span>2.4m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_height' />
                                </span>
                                <span>2.7m</span>
                              </span>
                            </div>

                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_max_number_pallet_places' />{' '}
                              16
                            </span>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() => {
                                setSelidbeTruck(1)
                              }}
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() => {
                                setSelidbeTruck(3)
                              }}
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img src={cerada125} alt='truck3' />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='SERVICES_PAGE_vehicle_tow_tarpaulin' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_length' />
                                </span>
                                <span>13.6m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_width' />
                                </span>
                                <span>2.48m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_height' />
                                </span>
                                <span>3.05m</span>
                              </span>
                            </div>

                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_max_number_pallet_places' />{' '}
                              34
                            </span>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() => {
                                setSelidbeTruck(2)
                              }}
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() => {
                                setSelidbeTruck(0)
                              }}
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={
                  classes['bottom'] + `${serviceNumber !== 0 && ' d-none'}`
                }
              >
                <div className={classes['bottom-row'] + ' row'}>
                  <h2>
                    <Translated Key='SERVICES_PAGE_questions' />
                    <br />
                    <span
                      className={
                        classes['top-buttons-rect'] + ' rect-secondary'
                      }
                    />
                    <Translated Key='SERVICES_PAGE_questions2' />
                  </h2>
                  <div className={classes['bottom-col'] + ' col-xl-5'}>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_international_removals_question1' />
                      }
                    >
                      <p>
                        <Translated Key='SERVICES_PAGE_international_removals_question1_text' />
                      </p>
                    </Dropdown>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_international_removals_question2' />
                      }
                    >
                      <p>
                        <Translated Key='SERVICES_PAGE_international_removals_question2_text' />
                      </p>
                    </Dropdown>
                  </div>
                  <div className={classes['bottom-col'] + ' col-xl-5'}>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_international_removals_question3' />
                      }
                    >
                      <p>
                        <Translated Key='SERVICES_PAGE_international_removals_question3_text' />
                      </p>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*1*/}
          <div className={classes['custom-slider-slide']}>
            <div className={classes['custom-slider-slide-content']}>
              <div className={classes['middle-row'] + ' row'}>
                <div className={classes['middle-row-left'] + ' col-xl-4'}>
                  <h4 className={classes['middle-row-left-sub-heading']}>
                    <span
                      className={
                        classes['sub-heading-rect'] + ' rect-secondary'
                      }
                    />
                    <Translated Key='SERVICES_PAGE_transportation_of_textiles_subtitle' />
                  </h4>
                  <p className={classes['middle-row-left-p-2']}>
                    <Translated Key='SERVICES_PAGE_transportation_of_textiles_text' />
                  </p>
                </div>
                <div className={classes['middle-row-right'] + ' col-xl-8'}>
                  {/* TEKSTIL TRUCKS */}
                  <div className={'row'}>
                    {tekstilTruck === 0 ? (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img src={furgon35} alt='truck3' />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='SERVICES_PAGE_vehicle_van_3.5t' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_length' />
                                </span>
                                <span>4.2m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_width' />
                                </span>
                                <span>2.1m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_height' />
                                </span>
                                <span>2.3m</span>
                              </span>
                            </div>

                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_max_number_pallet_places' />{' '}
                              8
                            </span>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() =>
                                setTekstilTruck((prev) => {
                                  if (prev === 0) return 1
                                  return prev - 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() =>
                                setTekstilTruck((prev) => {
                                  if (prev === 1) return 0
                                  return prev + 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : tekstilTruck === 1 ? (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img src={frigo75} alt='truck3' />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='SERVICES_PAGE_vehicle_solo_truck_furgon_7.5t' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_length' />
                                </span>
                                <span>6m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_width' />
                                </span>
                                <span>2.4m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_height' />
                                </span>
                                <span>2.7m</span>
                              </span>
                            </div>

                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_max_number_pallet_places' />{' '}
                              15
                            </span>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() =>
                                setTekstilTruck((prev) => {
                                  if (prev === 0) return 1
                                  return prev - 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() =>
                                setTekstilTruck((prev) => {
                                  if (prev === 1) return 0
                                  return prev + 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img src={furgon35} alt='truck3' />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='SERVICES_PAGE_vehicle_van_3.5t' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_length' />
                                </span>
                                <span>3.7m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_width' />
                                </span>
                                <span>1.5m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_height' />
                                </span>
                                <span>1.85m</span>
                              </span>
                            </div>

                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_total_volume' />
                            </span>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() =>
                                setTekstilTruck((prev) => {
                                  if (prev === 0) return 2
                                  return prev - 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() =>
                                setTekstilTruck((prev) => {
                                  if (prev === 2) return 0
                                  return prev + 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={
                  classes['bottom'] + `${serviceNumber !== 1 && ' d-none'}`
                }
              >
                <div className={classes['bottom-row'] + ' row'}>
                  <h2>
                    <Translated Key='SERVICES_PAGE_questions' />
                    <br />
                    <span
                      className={
                        classes['top-buttons-rect'] + ' rect-secondary'
                      }
                    />
                    <Translated Key='SERVICES_PAGE_questions2' />
                  </h2>
                  <div className={classes['bottom-col'] + ' col-xl-5'}>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_transportation_of_textiles_question1' />
                      }
                    >
                      <p>
                        <Translated Key='SERVICES_PAGE_transportation_of_textiles_question1_text' />
                      </p>
                    </Dropdown>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_transportation_of_textiles_question2' />
                      }
                    >
                      <p>
                        <Translated Key='SERVICES_PAGE_transportation_of_textiles_question2_text' />
                      </p>
                    </Dropdown>
                  </div>
                  <div className={classes['bottom-col'] + ' col-xl-5'}>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_transportation_of_textiles_question3' />
                      }
                    >
                      <p>
                        <Translated Key='SERVICES_PAGE_transportation_of_textiles_question3_text' />
                      </p>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*2*/}
          <div className={classes['custom-slider-slide']}>
            <div className={classes['custom-slider-slide-content']}>
              <div className={classes['middle-row'] + ' row'}>
                <div className={classes['middle-row-left'] + ' col-xl-4'}>
                  <h4 className={classes['middle-row-left-sub-heading']}>
                    <span
                      className={
                        classes['sub-heading-rect'] + ' rect-secondary'
                      }
                    />
                    <Translated Key='SERVICES_PAGE_frigo_transport_subtitle' />
                  </h4>
                  <p className={classes['middle-row-left-p-2']}>
                    <Translated Key='SERVICES_PAGE_frigo_transport_text_p1' />
                  </p>
                  <p className={classes['middle-row-left-p-2']}>
                    <Translated Key='SERVICES_PAGE_frigo_transport_text_p2' />
                  </p>
                </div>
                <div className={classes['middle-row-right'] + ' col-xl-8'}>
                  <div className={'row'}>
                    {/* FRIGO TRUCKS */}
                    {frigoTruck === 0 ? (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img src={frigo75} alt='truck3' />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='SERVICES_PAGE_vehicle_solo_refrigerator_12t' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_length' />
                                </span>
                                <span>7.2m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_width' />
                                </span>
                                <span>2.4m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_height' />
                                </span>
                                <span>2.3m</span>
                              </span>
                            </div>

                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_max_number_pallet_places' />{' '}
                              18
                            </span>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() =>
                                setFrigoTruck((prev) => {
                                  if (prev === 0) return 2
                                  return prev - 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() =>
                                setFrigoTruck((prev) => {
                                  if (prev === 2) return 0
                                  return prev + 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : frigoTruck === 1 ? (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img src={frigo35} alt='truck3' />
                          <img src={gdp} alt='' className={classes.subImage} />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='SERVICES_PAGE_vehicle_van_refrigerator_3.5t' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_length' />
                                </span>
                                <span>3.7m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_width' />
                                </span>
                                <span>4.2m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_height' />
                                </span>
                                <span>2.1m</span>
                              </span>
                            </div>

                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_max_number_pallet_places' />{' '}
                              8
                            </span>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() => setFrigoTruck(1)}
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() => setFrigoTruck(1)}
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img src={frigo35} alt='truck3' />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='SERVICES_PAGE_vehicle_van_refrigerator_3.5t' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_length' />
                                </span>
                                <span>3.7m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_width' />
                                </span>
                                <span>1.5m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_height' />
                                </span>
                                <span>1.85m</span>
                              </span>
                            </div>

                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_total_volume' />
                            </span>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() =>
                                setFrigoTruck((prev) => {
                                  if (prev === 0) return 2
                                  return prev - 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() =>
                                setFrigoTruck((prev) => {
                                  if (prev === 2) return 0
                                  return prev + 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={
                  classes['bottom'] + `${serviceNumber !== 2 && ' d-none'}`
                }
              >
                <div className={classes['bottom-row'] + ' row'}>
                  <h2>
                    <Translated Key='SERVICES_PAGE_questions' />
                    <br />
                    <span
                      className={
                        classes['top-buttons-rect'] + ' rect-secondary'
                      }
                    />
                    <Translated Key='SERVICES_PAGE_questions2' />
                  </h2>
                  <div className={classes['bottom-col'] + ' col-xl-5'}>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_frigo_transport_question1' />
                      }
                    >
                      <p>
                        <Translated Key='SERVICES_PAGE_frigo_transport_question1_text' />
                      </p>
                    </Dropdown>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_frigo_transport_question2' />
                      }
                    >
                      <p>
                        <Translated Key='SERVICES_PAGE_frigo_transport_question2_text' />
                      </p>
                    </Dropdown>
                  </div>
                  <div className={classes['bottom-col'] + ' col-xl-5'}>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_frigo_transport_question3' />
                      }
                    >
                      <p>
                        <Translated Key='SERVICES_PAGE_frigo_transport_question3_text' />
                      </p>
                    </Dropdown>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_frigo_transport_question4' />
                      }
                    >
                      <p>
                        <Translated Key='SERVICES_PAGE_frigo_transport_question4_text' />
                      </p>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*3*/}
          <div className={classes['custom-slider-slide']}>
            <div className={classes['custom-slider-slide-content']}>
              <div className={classes['middle-row'] + ' row'}>
                <div className={classes['middle-row-left'] + ' col-xl-4'}>
                  <h4 className={classes['middle-row-left-sub-heading']}>
                    <span
                      className={
                        classes['sub-heading-rect'] + ' rect-secondary'
                      }
                    />
                    <Translated Key='SERVICES_PAGE_adr_transport_subtitle' />
                  </h4>
                  <p className={classes['middle-row-left-p-2']}>
                    <Translated Key='SERVICES_PAGE_adr_transport_text_p1' />
                  </p>
                  <p className={classes['middle-row-left-p-2']}>
                    <Translated Key='SERVICES_PAGE_adr_transport_text_p2' />
                  </p>
                </div>
                <div className={classes['middle-row-right'] + ' col-xl-8'}>
                  <div className={'row'}>
                    {/* ADR TRUCKS */}
                    {adrTruck === 0 ? (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img src={cerada75} alt='truck3' />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='SERVICES_PAGE_vehicle_solo_truck_tarpaulin_7.5t' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_length' />
                                </span>
                                <span>6.2m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_width' />
                                </span>
                                <span>2.4m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_height' />
                                </span>
                                <span>2.7m</span>
                              </span>
                            </div>

                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_max_number_pallet_places' />{' '}
                              16
                            </span>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() => {
                                setAdrTruck(1)
                              }}
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() => {
                                setAdrTruck(1)
                              }}
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : adrTruck === 1 ? (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img src={cerada125} alt='truck3' />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='SERVICES_PAGE_vehicle_tow_tarpaulin' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_length' />
                                </span>
                                <span>13.6m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_width' />
                                </span>
                                <span>2.48m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_height' />
                                </span>
                                <span>3.05m</span>
                              </span>
                            </div>

                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_max_number_pallet_places' />{' '}
                              34
                            </span>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() => {
                                setAdrTruck(0)
                              }}
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() => {
                                setAdrTruck(0)
                              }}
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : adrTruck === 2 ? (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img src={frigo35} alt='truck3' />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='SERVICES_PAGE_vehicle_van_refrigerator_3.5t' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_length' />
                                </span>
                                <span>4.2m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_width' />
                                </span>
                                <span>2.1m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_height' />
                                </span>
                                <span>2.3m</span>
                              </span>
                            </div>

                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_max_number_pallet_places' />{' '}
                              8
                            </span>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() =>
                                setAdrTruck((prev) => {
                                  if (prev === 0) return 5
                                  return prev - 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() =>
                                setAdrTruck((prev) => {
                                  if (prev === 4) return 0
                                  return prev + 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : adrTruck === 3 ? (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img src={cerada35} alt='truck3' />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='SERVICES_PAGE_vehicle_van_tarpaulin_3.5t' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_length' />
                                </span>
                                <span>4.2m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_width' />
                                </span>
                                <span>2.1m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_height' />
                                </span>
                                <span>2.3m</span>
                              </span>
                            </div>

                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_max_number_pallet_places' />{' '}
                              8
                            </span>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() =>
                                setAdrTruck((prev) => {
                                  if (prev === 0) return 5
                                  return prev - 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() =>
                                setAdrTruck((prev) => {
                                  if (prev === 4) return 0
                                  return prev + 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : adrTruck === 4 ? (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img src={cerada75} alt='truck3' />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='SERVICES_PAGE_vehicle_solo_truck_tarpaulin_7.5t' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_length' />
                                </span>
                                <span>6.2m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_width' />
                                </span>
                                <span>2.4m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_height' />
                                </span>
                                <span>2.7m</span>
                              </span>
                            </div>

                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_max_number_pallet_places' />{' '}
                              16
                            </span>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() =>
                                setAdrTruck((prev) => {
                                  if (prev === 0) return 5
                                  return prev - 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() =>
                                setAdrTruck((prev) => {
                                  if (prev === 4) return 0
                                  return prev + 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img src={frigo35} alt='truck3' />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='SERVICES_PAGE_vehicle_van_refrigerator_3.5t' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_length' />
                                </span>
                                <span>3.7m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_width' />
                                </span>
                                <span>1.5m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_height' />
                                </span>
                                <span>1.85m</span>
                              </span>
                            </div>

                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_max_number_pallet_places' />{' '}
                              5
                            </span>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() =>
                                setAdrTruck((prev) => {
                                  if (prev === 0) return 5
                                  return prev - 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() =>
                                setAdrTruck((prev) => {
                                  if (prev === 4) return 0
                                  return prev + 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={
                  classes['bottom'] + `${serviceNumber !== 3 && ' d-none'}`
                }
              >
                <div className={classes['bottom-row'] + ' row'}>
                  <h2>
                    <Translated Key='SERVICES_PAGE_questions' />
                    <br />
                    <span
                      className={
                        classes['top-buttons-rect'] + ' rect-secondary'
                      }
                    />
                    <Translated Key='SERVICES_PAGE_questions2' />
                  </h2>
                  <div className={classes['bottom-col'] + ' col-xl-5'}>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_adr_transport_question1' />
                      }
                    >
                      <p>
                        <Translated Key='SERVICES_PAGE_adr_transport_question1_text' />
                      </p>
                    </Dropdown>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_adr_transport_question2' />
                      }
                    >
                      <p>
                        <Translated Key='SERVICES_PAGE_adr_transport_question2_text' />
                      </p>
                    </Dropdown>
                  </div>
                  <div className={classes['bottom-col'] + ' col-xl-5'}>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_adr_transport_question3' />
                      }
                    >
                      <p>
                        <Translated Key='SERVICES_PAGE_adr_transport_question3_text' />
                      </p>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*4*/}
          <div className={classes['custom-slider-slide']}>
            <div className={classes['custom-slider-slide-content']}>
              <div className={classes['middle-row'] + ' row'}>
                <div className={classes['middle-row-left'] + ' col-xl-4'}>
                  <h4 className={classes['middle-row-left-sub-heading']}>
                    <span
                      className={
                        classes['sub-heading-rect'] + ' rect-secondary'
                      }
                    />
                    <Translated Key='SERVICES_PAGE_oversized_transport_subtitle' />
                  </h4>
                  <p className={classes['middle-row-left-p-2']}>
                    <Translated Key='SERVICES_PAGE_oversized_transport_text_p1' />
                  </p>
                  <p className={classes['middle-row-left-p-2']}>
                    <Translated Key='SERVICES_PAGE_oversized_transport_text_p2' />
                  </p>
                </div>
                <div className={classes['middle-row-right'] + ' col-xl-8'}>
                  <div className={'row'}>
                    {/* VANGABARITNI TRUCKS */}
                    {vangabaritniTruck === 0 ? (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/prikolica3.png`}
                            alt='truck3'
                          />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='low_rider' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='height' />
                                </span>
                                <span>2.3m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='width' />
                                </span>
                                <span>2.55m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='length' />
                                </span>
                                <span>21.60m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='capacity' />
                                </span>
                                <span>47t</span>
                              </span>
                            </div>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() =>
                                setVangabaritniTruck((prev) => {
                                  if (prev === 0) return 6
                                  return prev - 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() =>
                                setVangabaritniTruck((prev) => {
                                  if (prev === 6) return 0
                                  return prev + 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : vangabaritniTruck === 1 ? (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/prikolica5.png`}
                            alt='truck3'
                          />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='krone_mega_liner' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='height' />
                                </span>
                                <span>3.30m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='width' />
                                </span>
                                <span>2.50m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='length' />
                                </span>
                                <span>13.6m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='capacity' />
                                </span>
                                <span>24t</span>
                              </span>
                            </div>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() =>
                                setVangabaritniTruck((prev) => {
                                  if (prev === 0) return 6
                                  return prev - 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() =>
                                setVangabaritniTruck((prev) => {
                                  if (prev === 6) return 0
                                  return prev + 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : vangabaritniTruck === 2 ? (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/prikolica4.png`}
                            alt='truck3'
                          />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='nooteboom_4' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='height' />
                                </span>
                                <span>2.85m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='width' />
                                </span>
                                <span>2.55m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='length' />
                                </span>
                                <span>15.8m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='capacity' />
                                </span>
                                <span>47t</span>
                              </span>
                            </div>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() =>
                                setVangabaritniTruck((prev) => {
                                  if (prev === 0) return 6
                                  return prev - 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() =>
                                setVangabaritniTruck((prev) => {
                                  if (prev === 6) return 0
                                  return prev + 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : vangabaritniTruck === 3 ? (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/prikolica1.png`}
                            alt='truck3'
                          />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='nooteboom_3' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='height' />
                                </span>
                                <span>2.2m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='width' />
                                </span>
                                <span>2.54m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='length' />
                                </span>
                                <span>15.8m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='capacity' />
                                </span>
                                <span>38t</span>
                              </span>
                            </div>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() =>
                                setVangabaritniTruck((prev) => {
                                  if (prev === 0) return 6
                                  return prev - 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() =>
                                setVangabaritniTruck((prev) => {
                                  if (prev === 6) return 0
                                  return prev + 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : vangabaritniTruck === 4 ? (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/prikolica2.png`}
                            alt='truck3'
                          />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='axle_platform' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='height' />
                                </span>
                                <span>2.5m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='width' />
                                </span>
                                <span>2.55m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='length' />
                                </span>
                                <span>20m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='capacity' />
                                </span>
                                <span>44t</span>
                              </span>
                            </div>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() =>
                                setVangabaritniTruck((prev) => {
                                  if (prev === 0) return 6
                                  return prev - 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() =>
                                setVangabaritniTruck((prev) => {
                                  if (prev === 6) return 0
                                  return prev + 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : vangabaritniTruck === 5 ? (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/prikolica2.png`}
                            alt='truck3'
                          />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='axle_platform2' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='height' />
                                </span>
                                <span>2.5m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='width' />
                                </span>
                                <span>2.55m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='length' />
                                </span>
                                <span>29m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='capacity' />
                                </span>
                                <span>44t</span>
                              </span>
                            </div>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() =>
                                setVangabaritniTruck((prev) => {
                                  if (prev === 0) return 6
                                  return prev - 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() =>
                                setVangabaritniTruck((prev) => {
                                  if (prev === 6) return 0
                                  return prev + 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : vangabaritniTruck === 6 ? (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img src={cerada125} alt='truck3' />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='SERVICES_PAGE_vehicle_tow_tarpaulin_mega' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_length' />
                                </span>
                                <span>13.6m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_width' />
                                </span>
                                <span>2.48m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_height' />
                                </span>
                                <span>3.05m</span>
                              </span>
                            </div>

                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_max_number_pallet_places' />{' '}
                              34
                            </span>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() =>
                                setVangabaritniTruck((prev) => {
                                  if (prev === 0) return 6
                                  return prev - 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() =>
                                setVangabaritniTruck((prev) => {
                                  if (prev === 6) return 0
                                  return prev + 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : vangabaritniTruck === 7 ? (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img src={mega} alt='truck3' />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='SERVICES_PAGE_vehicle_labudica' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_length' />
                                </span>
                                <span>16.09m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_width' />
                                </span>
                                <span>2.55m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_height' />
                                </span>
                                <span>2.85m</span>
                              </span>
                            </div>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() =>
                                setVangabaritniTruck((prev) => {
                                  if (prev === 0) return 6
                                  return prev - 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() =>
                                setVangabaritniTruck((prev) => {
                                  if (prev === 6) return 0
                                  return prev + 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/prikolica5.png`}
                            alt='truck3'
                          />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='nooteboom_3' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='height' />
                                </span>
                                <span>13.6m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='width' />
                                </span>
                                <span>2.48m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='length' />
                                </span>
                                <span>3.05m</span>
                              </span>
                            </div>

                            <span className={classes['bolded-span']}>
                              <Translated Key='capacity' /> 8
                            </span>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() =>
                                setVangabaritniTruck((prev) => {
                                  if (prev === 0) return 6
                                  return prev - 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() =>
                                setVangabaritniTruck((prev) => {
                                  if (prev === 6) return 0
                                  return prev + 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {+serviceNumber === 4 && (
                  <iframe
                    className={classes['video']}
                    src='https://www.youtube.com/embed/-GgExLt0oG8'
                    title='Special Transport - Inđija / Specijalni transport - Inđija'
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                  ></iframe>
                )}
              </div>
              <div
                className={
                  classes['bottom'] + `${serviceNumber !== 4 && ' d-none'}`
                }
              >
                <div className={classes['bottom-row'] + ' row'}>
                  <h2>
                    <Translated Key='SERVICES_PAGE_questions' />
                    <br />
                    <span
                      className={
                        classes['top-buttons-rect'] + ' rect-secondary'
                      }
                    />
                    <Translated Key='SERVICES_PAGE_questions2' />
                  </h2>
                  <div className={classes['bottom-col'] + ' col-xl-5'}>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_oversized_transport_question1' />
                      }
                    >
                      <p>
                        <Translated Key='SERVICES_PAGE_oversized_transport_question1_text' />
                      </p>
                    </Dropdown>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_oversized_transport_question2' />
                      }
                    >
                      <p>
                        <Translated Key='SERVICES_PAGE_oversized_transport_question2_text' />
                      </p>
                    </Dropdown>
                  </div>
                  <div className={classes['bottom-col'] + ' col-xl-5'}>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_oversized_transport_question3' />
                      }
                    >
                      <p>
                        <Translated Key='SERVICES_PAGE_oversized_transport_question3_text' />
                      </p>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*5*/}
          <div className={classes['custom-slider-slide']}>
            <div className={classes['custom-slider-slide-content']}>
              <div className={classes['middle-row'] + ' row'}>
                <div className={classes['middle-row-left'] + ' col-xl-6'}>
                  <h4 className={classes['middle-row-left-sub-heading']}>
                    <span
                      className={
                        classes['sub-heading-rect'] + ' rect-secondary'
                      }
                    />
                    <Translated Key='SERVICES_PAGE_customs_representation_subtitle' />
                  </h4>
                  <p className={classes['middle-row-left-p-2']}>
                    <Translated Key='SERVICES_PAGE_customs_representation_text' />
                  </p>
                </div>
                <div
                  className={
                    classes['border-check-img-container'] + ' col-xl-6'
                  }
                >
                  <img src={borderCheckImg} alt='border-check' />
                </div>

                {/*<div className={classes["middle-row-right"] + " col-xl-8"}>
                   <div className={"row"}>
                    CARINSKO TRUCK
                  {tekstilTruck === 0 ? (
                      <>
                        <div
                          className={
                            classes["middle-row-right-truck-container"] +
                            " col-md-8"
                          }
                        >
                          <img src={truck3Img} alt="truck3" />
                        </div>
                        <div
                          className={
                            classes["middle-row-right-details-container"] +
                            " col-md-4 col-sm-12"
                          }
                        >
                          <div className={classes["middle-row-right-details"]}>
                            <h5>Kombi do 3.5t</h5>
                            <span className={classes["bolded-span"]}>
                              Dimenzije tovarnog prostora
                            </span>
                            <div className={classes["double-span-container"]}>
                              <span className={classes["double-span"]}>
                                <span>dužina</span>
                                <span>4.2m</span>
                              </span>
                              <span className={classes["double-span"]}>
                                <span><Translated Key="SERVICES_PAGE_width"/></span>
                                <span>2.1m</span>
                              </span>
                              <span className={classes["double-span"]}>
                                <span>visina</span>
                                <span>2.3m</span>
                              </span>
                            </div>

                            <span className={classes["bolded-span"]}>
                              Maksimalan broj paletnih mesta 8
                            </span>
                          </div>
                          <div className={classes["arrows"]}>
                            <button
                              className={"arrow-left-white"}
                              onClick={()=>setTekstilTruck((prev) =>{
                                if(prev===0) return 2;
                                return prev-1;
                              })}>
                            
                              <img src={arrowImg} alt="arrow" />
                            </button>

                            <span>Lista vozila</span>
                            <button
                              className={"arrow-right-white"}
                              onClick={()=>setTekstilTruck((prev) =>{
                                if(prev===2) return 0;
                                return prev+1;
                              })}>
                            
                              <img src={arrowImg} alt="arrow" />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : tekstilTruck===1 ? (
                      <>
                        <div
                          className={
                            classes["middle-row-right-truck-container"] +
                            " col-md-8"
                          }
                        >
                          <img src={truck3Img} alt="truck3" />
                        </div>
                        <div
                          className={
                            classes["middle-row-right-details-container"] +
                            " col-md-4 col-sm-12"
                          }
                        >
                          <div className={classes["middle-row-right-details"]}>
                            <h5>Solo kamion furgon do 7.5t</h5>
                            <span className={classes["bolded-span"]}>
                              Dimenzije tovarnog prostora
                            </span>
                            <div className={classes["double-span-container"]}>
                              <span className={classes["double-span"]}>
                                <span>dužina</span>
                                <span>6m</span>
                              </span>
                              <span className={classes["double-span"]}>
                                <span><Translated Key="SERVICES_PAGE_width"/></span>
                                <span>2.4m</span>
                              </span>
                              <span className={classes["double-span"]}>
                                <span>visina</span>
                                <span>2.3m</span>
                              </span>
                            </div>

                            <span className={classes["bolded-span"]}>
                              Maksimalan broj paletnih mesta 15
                            </span>
                          </div>
                          <div className={classes["arrows"]}>
                            <button className={"arrow-left-white"}
                            onClick={()=>setTekstilTruck((prev) =>{
                              if(prev===0) return 2;
                              return prev-1;
                            })}>
                              <img src={arrowImg} alt="arrow" />
                            </button>

                            <span>Lista vozila</span>
                            <button className={"arrow-right-white"} 
                            onClick={()=>setTekstilTruck((prev) =>{
                              if(prev===2) return 0;
                              return prev+1;
                            })}>
                              <img src={arrowImg} alt="arrow" />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : 
                    <>
                      <div
                        className={
                          classes["middle-row-right-truck-container"] +
                          " col-md-8"
                        }
                      >
                        <img src={truck3Img} alt="truck3" />
                      </div>
                      <div
                        className={
                          classes["middle-row-right-details-container"] +
                          " col-md-4 col-sm-12"
                        }
                      >
                        <div className={classes["middle-row-right-details"]}>
                          <h5>Kombi do 3.5t</h5>
                          <span className={classes["bolded-span"]}>
                            Dimenzije tovarnog prostora
                          </span>
                          <div className={classes["double-span-container"]}>
                            <span className={classes["double-span"]}>
                              <span>dužina</span>
                              <span>3.7m</span>
                            </span>
                            <span className={classes["double-span"]}>
                              <span><Translated Key="SERVICES_PAGE_width"/></span>
                              <span>1.5m</span>
                            </span>
                            <span className={classes["double-span"]}>
                              <span>visina</span>
                              <span>1.85m</span>
                            </span>
                          </div>

                          <span className={classes["bolded-span"]}>
                            Ukupna zapremina tovarnog prostora je 100m3
                          </span>
                        </div>
                        <div className={classes["arrows"]}>
                          <button className={"arrow-left-white"}
                            onClick={()=>setTekstilTruck((prev) =>{
                              if(prev===0) return 2;
                              return prev-1;
                            })}>
                            <img src={arrowImg} alt="arrow" />
                          </button>

                          <span>Lista vozila</span>
                          <button className={"arrow-right-white"} 
                            onClick={()=>setTekstilTruck((prev) =>{
                              if(prev===2) return 0;
                              return prev+1;
                            })}>
                            <img src={arrowImg} alt="arrow" />
                          </button>
                        </div>
                      </div>
                    </>}
                  </div> 
                </div>*/}
              </div>
              <div
                className={
                  classes['bottom'] + `${serviceNumber !== 5 && ' d-none'}`
                }
              >
                <div className={classes['bottom-row'] + ' row'}>
                  <h2>
                    <Translated Key='SERVICES_PAGE_questions' />
                    <br />
                    <span
                      className={
                        classes['top-buttons-rect'] + ' rect-secondary'
                      }
                    />
                    <Translated Key='SERVICES_PAGE_questions2' />
                  </h2>

                  <div className={classes['bottom-col'] + ' col-xl-5'}>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_customs_representation_question1' />
                      }
                    >
                      <ul className={classes['faq-list']}>
                        <li>
                          <Translated Key='SERVICES_PAGE_customs_representation_question1_text_li1' />
                        </li>
                        <li>
                          <Translated Key='SERVICES_PAGE_customs_representation_question1_text_li2' />
                        </li>
                        <li>
                          <Translated Key='SERVICES_PAGE_customs_representation_question1_text_li3' />
                        </li>
                        <li>
                          <Translated Key='SERVICES_PAGE_customs_representation_question1_text_li4' />
                        </li>
                      </ul>
                    </Dropdown>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_customs_representation_question2' />
                      }
                    >
                      <p>
                        <Translated Key='SERVICES_PAGE_customs_representation_question2_text' />
                      </p>
                    </Dropdown>
                  </div>
                  <div className={classes['bottom-col'] + ' col-xl-5'}>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_customs_representation_question3' />
                      }
                    >
                      <p className={classes['margin-bottom-1rem']}>
                        <Translated Key='SERVICES_PAGE_customs_representation_question3_text_p1' />
                        <br />
                      </p>
                      <p className={classes['margin-bottom-1rem']}>
                        <Translated Key='SERVICES_PAGE_customs_representation_question3_text_p2' />
                      </p>
                      <p className={classes['margin-bottom-1rem']}>
                        <Translated Key='SERVICES_PAGE_customs_representation_question3_text_p3' />
                      </p>
                      <p>
                        <Translated Key='SERVICES_PAGE_customs_representation_question3_text_p4' />
                      </p>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*6*/}
          <div className={classes['custom-slider-slide']}>
            <div className={classes['custom-slider-slide-content']}>
              <div className={classes['middle-row'] + ' row'}>
                <div className={classes['middle-row-left'] + ' col-xl-4'}>
                  <h4 className={classes['middle-row-left-sub-heading']}>
                    <span
                      className={
                        classes['sub-heading-rect'] + ' rect-secondary'
                      }
                    />
                    <Translated Key='SERVICES_PAGE_transport_of_commercial_goods_subtitle' />
                  </h4>
                  <p className={classes['middle-row-left-p-2']}>
                    <Translated Key='SERVICES_PAGE_transport_of_commercial_goods_text_p1' />
                  </p>
                  <p className={classes['middle-row-left-p-2']}>
                    <Translated Key='SERVICES_PAGE_transport_of_commercial_goods_text_p2' />
                  </p>
                  <p className={classes['middle-row-left-p-2']}>
                    <Translated Key='SERVICES_PAGE_transport_of_commercial_goods_text_p3' />
                  </p>
                </div>
                <div className={classes['middle-row-right'] + ' col-xl-8'}>
                  <div className={'row'}>
                    {/* KOMERCIJALNIH TRUCKS */}
                    {komercijalnoTruck === 0 ? (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img src={furgon35} alt='truck3' />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='SERVICES_PAGE_vehicle_van_3.5t' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_length' />
                                </span>
                                <span>4.2m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_width' />
                                </span>
                                <span>2.1m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_height' />
                                </span>
                                <span>2.3m</span>
                              </span>
                            </div>

                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_max_number_pallet_places' />{' '}
                              8
                            </span>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() =>
                                setKomercijalnoTruck((prev) => {
                                  if (prev === 0) return 5
                                  return prev - 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() =>
                                setKomercijalnoTruck((prev) => {
                                  if (prev === 5) return 0
                                  return prev + 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : komercijalnoTruck === 1 ? (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img src={frigo35} alt='truck3' />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='SERVICES_PAGE_vehicle_van_refrigerator_3.5t' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_length' />
                                </span>
                                <span>3.7m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_width' />
                                </span>
                                <span>4.2m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_height' />
                                </span>
                                <span>2.1m</span>
                              </span>
                            </div>

                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_max_number_pallet_places' />{' '}
                              8
                            </span>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() =>
                                setKomercijalnoTruck((prev) => {
                                  if (prev === 0) return 5
                                  return prev - 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() =>
                                setKomercijalnoTruck((prev) => {
                                  if (prev === 5) return 0
                                  return prev + 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : komercijalnoTruck === 2 ? (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img src={cerada35} alt='truck3' />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='SERVICES_PAGE_vehicle_van_tarpaulin_3.5t' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_length' />
                                </span>
                                <span>4.2m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_width' />
                                </span>
                                <span>2.1m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_height' />
                                </span>
                                <span>2.3m</span>
                              </span>
                            </div>

                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_max_number_pallet_places' />{' '}
                              8
                            </span>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() =>
                                setKomercijalnoTruck((prev) => {
                                  if (prev === 0) return 5
                                  return prev - 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() =>
                                setKomercijalnoTruck((prev) => {
                                  if (prev === 5) return 0
                                  return prev + 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : komercijalnoTruck === 3 ? (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img src={cerada75} alt='truck3' />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='SERVICES_PAGE_vehicle_solo_truck_tarpaulin_7.5t' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_length' />
                                </span>
                                <span>6.2m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_width' />
                                </span>
                                <span>2.4m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_height' />
                                </span>
                                <span>2.7m</span>
                              </span>
                            </div>

                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_total_volume' />
                            </span>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() =>
                                setKomercijalnoTruck((prev) => {
                                  if (prev === 0) return 5
                                  return prev - 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() =>
                                setKomercijalnoTruck((prev) => {
                                  if (prev === 5) return 0
                                  return prev + 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : komercijalnoTruck === 4 ? (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img src={frigo75} alt='truck3' />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='SERVICES_PAGE_vehicle_solo_truck_furgon_7.5t' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_length' />
                                </span>
                                <span>6m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_width' />
                                </span>
                                <span>2.4m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_height' />
                                </span>
                                <span>2.7m</span>
                              </span>
                            </div>

                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_max_number_pallet_places' />{' '}
                              15
                            </span>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() =>
                                setKomercijalnoTruck((prev) => {
                                  if (prev === 0) return 5
                                  return prev - 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() =>
                                setKomercijalnoTruck((prev) => {
                                  if (prev === 5) return 0
                                  return prev + 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className={
                            classes['middle-row-right-truck-container'] +
                            ' col-md-8'
                          }
                        >
                          <img src={cerada125} alt='truck3' />
                        </div>
                        <div
                          className={
                            classes['middle-row-right-details-container'] +
                            ' col-md-4 col-sm-12'
                          }
                        >
                          <div className={classes['middle-row-right-details']}>
                            <h5>
                              <Translated Key='SERVICES_PAGE_vehicle_tow_tarpaulin' />
                            </h5>
                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_dimensions' />
                            </span>
                            <div className={classes['double-span-container']}>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_length' />
                                </span>
                                <span>13.6m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_width' />
                                </span>
                                <span>2.48m</span>
                              </span>
                              <span className={classes['double-span']}>
                                <span>
                                  <Translated Key='SERVICES_PAGE_height' />
                                </span>
                                <span>3.05m</span>
                              </span>
                            </div>

                            <span className={classes['bolded-span']}>
                              <Translated Key='SERVICES_PAGE_max_number_pallet_places' />{' '}
                              34
                            </span>
                          </div>
                          <div className={classes['arrows']}>
                            <button
                              className={'arrow-left-white'}
                              onClick={() =>
                                setKomercijalnoTruck((prev) => {
                                  if (prev === 0) return 5
                                  return prev - 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>

                            <span>
                              <Translated Key='SERVICES_PAGE_vehicles_list' />
                            </span>
                            <button
                              className={'arrow-right-white'}
                              onClick={() =>
                                setKomercijalnoTruck((prev) => {
                                  if (prev === 5) return 0
                                  return prev + 1
                                })
                              }
                            >
                              <img src={arrowImg} alt='arrow' />
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div
                className={
                  classes['bottom'] + `${serviceNumber !== 6 && ' d-none'}`
                }
              >
                <div className={classes['bottom-row'] + ' row'}>
                  <h2>
                    <Translated Key='SERVICES_PAGE_questions' />
                    <br />
                    <span
                      className={
                        classes['top-buttons-rect'] + ' rect-secondary'
                      }
                    />
                    <Translated Key='SERVICES_PAGE_questions2' />
                  </h2>

                  <div className={classes['bottom-col'] + ' col-xl-5'}>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_transport_of_commercial_goods_question1' />
                      }
                    >
                      <p className={classes['margin-bottom-1rem']}>
                        <Translated Key='SERVICES_PAGE_transport_of_commercial_goods_question1_text_p1' />
                      </p>
                      <p className={classes['margin-bottom-1rem']}>
                        <Translated Key='SERVICES_PAGE_transport_of_commercial_goods_question1_text_p2' />
                      </p>
                      <p>
                        <Translated Key='SERVICES_PAGE_transport_of_commercial_goods_question1_text_p3' />
                      </p>
                    </Dropdown>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_transport_of_commercial_goods_question2' />
                      }
                    >
                      <p>
                        <Translated Key='SERVICES_PAGE_transport_of_commercial_goods_question2_text' />
                      </p>
                    </Dropdown>
                  </div>
                  <div className={classes['bottom-col'] + ' col-xl-5'}>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_transport_of_commercial_goods_question3' />
                      }
                    >
                      <p>
                        <Translated Key='SERVICES_PAGE_transport_of_commercial_goods_question3_text' />
                      </p>
                    </Dropdown>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_transport_of_commercial_goods_question4' />
                      }
                    >
                      <p className={classes['margin-bottom-1rem']}>
                        <Translated Key='SERVICES_PAGE_transport_of_commercial_goods_question4_text_p1' />
                      </p>
                      <p>
                        <Translated Key='SERVICES_PAGE_transport_of_commercial_goods_question4_text_p2' />
                      </p>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*7*/}
          <div className={classes['custom-slider-slide']}>
            <div className={classes['custom-slider-slide-content']}>
              <div className={classes['middle-row'] + ' row'}>
                <div className={classes['middle-row-left'] + ' col-xl-6'}>
                  <h4 className={classes['middle-row-left-sub-heading']}>
                    <span
                      className={
                        classes['sub-heading-rect'] + ' rect-secondary'
                      }
                    />
                    <Translated Key='SERVICES_PAGE_container_avio_transport_subtitle' />
                  </h4>
                  <p className={classes['middle-row-left-p-2']}>
                    <Translated Key='SERVICES_PAGE_container_avio_transport_text_p1' />
                  </p>
                  <p className={classes['middle-row-left-p-2']}>
                    <Translated Key='SERVICES_PAGE_container_avio_transport_text_p2' />
                  </p>
                </div>
                <div className={classes['boat-img-container'] + ' col-6'}>
                  <img src={boatImg} alt='boat' />
                </div>
                {/* <div className={classes["middle-row-right"] + " col-xl-8"}>
                  <div className={"row"}>
                    {tekstilTruck === 0 ? (
                      <>
                        <div
                          className={
                            classes["middle-row-right-truck-container"] +
                            " col-md-8"
                          }
                        >
                          <img src={truck3Img} alt="truck3" />
                        </div>
                        <div
                          className={
                            classes["middle-row-right-details-container"] +
                            " col-md-4 col-sm-12"
                          }
                        >
                          <div className={classes["middle-row-right-details"]}>
                            <h5><Translated Key="SERVICES_PAGE_vehicle_van_3.5t"/></h5>
                            <span className={classes["bolded-span"]}>
                            <Translated Key="SERVICES_PAGE_dimensions"/>
                            </span>
                            <div className={classes["double-span-container"]}>
                              <span className={classes["double-span"]}>
                                <span><Translated Key="SERVICES_PAGE_length"/></span>
                                <span>4.2m</span>
                              </span>
                              <span className={classes["double-span"]}>
                                <span><Translated Key="SERVICES_PAGE_width"/></span>
                                <span>2.1m</span>
                              </span>
                              <span className={classes["double-span"]}>
                                <span><Translated Key="SERVICES_PAGE_height"/></span>
                                <span>2.3m</span>
                              </span>
                            </div>

                            <span className={classes["bolded-span"]}>
                            <Translated Key="SERVICES_PAGE_max_number_pallet_places"/> 8
                            </span>
                          </div>
                          <div className={classes["arrows"]}>
                            <button
                              className={"arrow-left-white"}
                              onClick={() =>
                                setTekstilTruck((prev) => {
                                  if (prev === 0) return 2;
                                  return prev - 1;
                                })
                              }
                            >
                              <img src={arrowImg} alt="arrow" />
                            </button>

                            <span><Translated Key="SERVICES_PAGE_vehicles_list"/></span>
                            <button
                              className={"arrow-right-white"}
                              onClick={() =>
                                setTekstilTruck((prev) => {
                                  if (prev === 2) return 0;
                                  return prev + 1;
                                })
                              }
                            >
                              <img src={arrowImg} alt="arrow" />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : tekstilTruck === 1 ? (
                      <>
                        <div
                          className={
                            classes["middle-row-right-truck-container"] +
                            " col-md-8"
                          }
                        >
                          <img src={truck3Img} alt="truck3" />
                        </div>
                        <div
                          className={
                            classes["middle-row-right-details-container"] +
                            " col-md-4 col-sm-12"
                          }
                        >
                          <div className={classes["middle-row-right-details"]}>
                            <h5><Translated Key="SERVICES_PAGE_vehicle_solo_truck_furgon_7.5t"/></h5>
                            <span className={classes["bolded-span"]}>
                            <Translated Key="SERVICES_PAGE_dimensions"/>
                            </span>
                            <div className={classes["double-span-container"]}>
                              <span className={classes["double-span"]}>
                                <span><Translated Key="SERVICES_PAGE_length"/></span>
                                <span>6m</span>
                              </span>
                              <span className={classes["double-span"]}>
                                <span><Translated Key="SERVICES_PAGE_width"/></span>
                                <span>2.4m</span>
                              </span>
                              <span className={classes["double-span"]}>
                                <span><Translated Key="SERVICES_PAGE_height"/></span>
                                <span>2.3m</span>
                              </span>
                            </div>

                            <span className={classes["bolded-span"]}>
                            <Translated Key="SERVICES_PAGE_max_number_pallet_places"/> 15
                            </span>
                          </div>
                          <div className={classes["arrows"]}>
                            <button
                              className={"arrow-left-white"}
                              onClick={() =>
                                setTekstilTruck((prev) => {
                                  if (prev === 0) return 2;
                                  return prev - 1;
                                })
                              }
                            >
                              <img src={arrowImg} alt="arrow" />
                            </button>

                            <span><Translated Key="SERVICES_PAGE_vehicles_list"/></span>
                            <button
                              className={"arrow-right-white"}
                              onClick={() =>
                                setTekstilTruck((prev) => {
                                  if (prev === 2) return 0;
                                  return prev + 1;
                                })
                              }
                            >
                              <img src={arrowImg} alt="arrow" />
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className={
                            classes["middle-row-right-truck-container"] +
                            " col-md-8"
                          }
                        >
                          <img src={truck3Img} alt="truck3" />
                        </div>
                        <div
                          className={
                            classes["middle-row-right-details-container"] +
                            " col-md-4 col-sm-12"
                          }
                        >
                          <div className={classes["middle-row-right-details"]}>
                            <h5><Translated Key="SERVICES_PAGE_vehicle_van_3.5t"/></h5>
                            <span className={classes["bolded-span"]}>
                            <Translated Key="SERVICES_PAGE_dimensions"/>
                            </span>
                            <div className={classes["double-span-container"]}>
                              <span className={classes["double-span"]}>
                                <span><Translated Key="SERVICES_PAGE_length"/></span>
                                <span>3.7m</span>
                              </span>
                              <span className={classes["double-span"]}>
                                <span><Translated Key="SERVICES_PAGE_width"/></span>
                                <span>1.5m</span>
                              </span>
                              <span className={classes["double-span"]}>
                                <span><Translated Key="SERVICES_PAGE_height"/></span>
                                <span>1.85m</span>
                              </span>
                            </div>

                            <span className={classes["bolded-span"]}>
                              <Translated Key="SERVICES_PAGE_total_volume"/>
                            </span>
                          </div>
                          <div className={classes["arrows"]}>
                            <button
                              className={"arrow-left-white"}
                              onClick={() =>
                                setTekstilTruck((prev) => {
                                  if (prev === 0) return 2;
                                  return prev - 1;
                                })
                              }
                            >
                              <img src={arrowImg} alt="arrow" />
                            </button>

                            <span><Translated Key="SERVICES_PAGE_vehicles_list"/></span>
                            <button
                              className={"arrow-right-white"}
                              onClick={() =>
                                setTekstilTruck((prev) => {
                                  if (prev === 2) return 0;
                                  return prev + 1;
                                })
                              }
                            >
                              <img src={arrowImg} alt="arrow" />
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div> */}
              </div>
              <div
                className={
                  classes['bottom'] + `${serviceNumber !== 7 && ' d-none'}`
                }
              >
                <div className={classes['bottom-row'] + ' row'}>
                  <h2>
                    <Translated Key='SERVICES_PAGE_questions' />
                    <br />
                    <span
                      className={
                        classes['top-buttons-rect'] + ' rect-secondary'
                      }
                    />
                    <Translated Key='SERVICES_PAGE_questions2' />
                  </h2>

                  <div className={classes['bottom-col'] + ' col-xl-5'}>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_container_avio_transport_question1' />
                      }
                    >
                      <p>
                        <Translated Key='SERVICES_PAGE_container_avio_transport_question1_text' />
                      </p>
                    </Dropdown>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_container_avio_transport_question2' />
                      }
                    >
                      <ul className={classes['faq-list']}>
                        <li>
                          <Translated Key='SERVICES_PAGE_container_avio_transport_question2_text_li1' />
                        </li>
                        <li>
                          <Translated Key='SERVICES_PAGE_container_avio_transport_question2_text_li2' />
                        </li>
                        <li>
                          <Translated Key='SERVICES_PAGE_container_avio_transport_question2_text_li3' />
                        </li>
                        <li>
                          <Translated Key='SERVICES_PAGE_container_avio_transport_question2_text_li4' />
                        </li>
                        <li>
                          <Translated Key='SERVICES_PAGE_container_avio_transport_question2_text_li5' />
                        </li>
                        <li>
                          <Translated Key='SERVICES_PAGE_container_avio_transport_question2_text_li6' />
                        </li>
                        <li>
                          <Translated Key='SERVICES_PAGE_container_avio_transport_question2_text_li7' />
                        </li>
                      </ul>
                    </Dropdown>
                  </div>
                  <div className={classes['bottom-col'] + ' col-xl-5'}>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_container_avio_transport_question3' />
                      }
                    >
                      <p>
                        <Translated Key='SERVICES_PAGE_container_avio_transport_question3_text' />
                      </p>
                    </Dropdown>
                    <Dropdown
                      title={
                        <Translated Key='SERVICES_PAGE_container_avio_transport_question4' />
                      }
                    >
                      <p>
                        <Translated Key='SERVICES_PAGE_container_avio_transport_question4_text' />
                      </p>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServicesPage
