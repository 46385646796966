
import classes from './PartnerContainer.module.scss';

const PartnerContainer = ({source, className}) => {
    return (
        <div className={classes['container']+' '+ className}>
            <img src={source} alt='partner'/>
        </div>
    );
}

export default PartnerContainer;