import classes from './DeliveryForm.module.scss'
import arrowImg from '../../assets/images/icons/arrow-right.png'
import road2Img from '../../assets/images/road2.jpeg'
import TransportInfoModal from './TransportInfoModal'
import { useCallback, useState } from 'react'
import Translated from '../../context/state'
import { apiHandler } from '../../api/api'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Link } from 'react-router-dom'

const DeliveryForm = ({ className, banner, modal }) => {
  const [formData, setFormData] = useState({
    company_name: '',
    contact_person: '',
    address: '',
    phone: '',
  })
  const [message, setMessage] = useState(null)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [token, setToken] = useState()
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)

  const api = apiHandler()

  const formChangeHandler = ({ target }) => {
    setFormData({ ...formData, [target.name]: target.value })
  }

  const verifyCaptcha = useCallback((token) => {
    setToken(token)
  }, [])

  const formSubmit = () => {
    if (!isLoading) {
      setIsLoading(true)
      setRefreshReCaptcha((r) => !r)
      api
        .post(`contact-form/main-price`, { ...formData, captcha_token: token })
        .then((response) => {
          console.log(response)
          if (response?.success) {
            setMessage(response.message)
            setIsError(false)
            setFormData({
              company_name: '',
              contact_person: '',
              address: '',
              phone: '',
            })
          } else {
            setMessage(response.message)
            setIsError(true)
          }

          setIsLoading(false)
        })
        .catch((error) => {
          setMessage(Object.values(error.response.data.message)[0][0])
          setIsError(true)
          setIsLoading(false)
        })
    }
  }

  const [showModal, setShowModal] = useState(false)

  const chooseTransportHandler = () => {
    for (const item of Object.values(formData)) {
      if (item === '') {
        setMessage(<Translated Key="name_required" />)
        setIsError(true)
        return
      }
    }
    setShowModal(true)
  }

  return (
    <>
      <GoogleReCaptcha
        onVerify={verifyCaptcha}
        refreshReCaptcha={refreshReCaptcha}
      />
      <TransportInfoModal
        openModal={showModal}
        contactData={formData}
        handleClose={() => setShowModal(false)}
      />
      <div className={classes['top-form-container'] + ' ' + className}>
        {banner && (
          <div className={classes['value-of-quote-container']}>
            <span className={classes['voq-value']}>
              <Translated Key='SERVICES_PAGE_transportation_costs' />
            </span>
            <h2 className={classes['voq-text']}>
              <Translated Key='FORM_know_price' /> <br />
              <span className={classes['rect-3'] + ' rect-secondary'}></span>
              <Translated Key='FORM_of_your_delivery' />
            </h2>
          </div>
        )}
        <div
          className={
            classes['top-form'] + ' row ' + `${modal && classes['modal']}`
          }
        >
          <div
            className={
              classes['top-form-left'] +
              ` ${!modal && ' col-xxl-10 '} col-xl-12`
            }
          >
            <div className={'row'}>
              <div
                className={
                  classes['float-input-div-container'] +
                  `${!modal ? ' col-lg-6' : ' col-xl-6'} `
                }
              >
                <div className={'float-input-div'}>
                  <input
                    type='text'
                    value={formData.company_name}
                    onChange={formChangeHandler}
                    name='company_name'
                  />
                  <label>
                    <Translated Key='SERVICES_PAGE_company_name' />:
                  </label>
                </div>
              </div>
              <div
                className={
                  classes['float-input-div-container'] +
                  `${!modal ? ' col-lg-6' : ' col-xl-6'} `
                }
              >
                <div className={'float-input-div'}>
                  <input
                    type='text'
                    value={formData.contact_person}
                    onChange={formChangeHandler}
                    name='contact_person'
                  />
                  <label>
                    <Translated Key='SERVICES_PAGE_contact_person' />:
                  </label>
                </div>
              </div>
              <div
                className={
                  classes['float-input-div-container'] +
                  `${!modal ? ' col-lg-6' : ' col-xl-6'} `
                }
              >
                <div className={'float-input-div'}>
                  <input
                    type='text'
                    value={formData.phone}
                    onChange={formChangeHandler}
                    name='phone'
                  />
                  <label>
                    <Translated Key='SERVICES_PAGE_phone' />:
                  </label>
                </div>
              </div>
              <div
                className={
                  classes['float-input-div-container'] +
                  `${!modal ? ' col-lg-6' : ' col-xl-6'} `
                }
              >
                <div className={'float-input-div'}>
                  <input
                    type='text'
                    value={formData.address}
                    onChange={formChangeHandler}
                    name='address'
                  />
                  <label>
                    <Translated Key='SERVICES_PAGE_email' />:
                  </label>
                </div>
              </div>
              {message && (
                <div
                  className={
                    classes['float-input-div-container'] +
                    `${!modal ? ' col-lg-12' : ' col-xl-12'} `
                  }
                >
                  <div className={'float-input-div'}>
                    <p style={{ color: isError ? 'red' : 'green' }}>
                      <Translated Key={message} />
                    </p>
                  </div>
                </div>
              )}
              <div
                className={
                  classes['float-input-div-container'] +
                  `${!modal ? ' col-lg-6' : ' col-xl-6'} ` +
                  classes['order']
                }
              >
                <button
                  className={classes['form-send-request'] + ' button-primary'}
                  onClick={chooseTransportHandler}
                >
                  <span className={'button-span-1'}>
                    <Translated Key='SERVICES_PAGE_choose_transport_type' />
                  </span>
                  <span className={'button-span-2 bs2-35-2'}>
                    <span
                      className={
                        classes['form-send-request-arrow'] +
                        ' arrow-right-black'
                      }
                    >
                      <img src={arrowImg} alt='arrow' />
                    </span>
                  </span>
                </button>
              </div>
              <div
                className={
                  classes['float-input-div-container'] +
                  `${!modal ? ' col-lg-6' : ' col-xl-6'} ` +
                  classes['order']
                }
              >
                <Link
                  to='/kontakt'
                  state={{ ...formData }}
                  className={classes['form-send-request']}
                >
                  <button
                    onClick={formSubmit}
                    className={classes['form-send-request'] + ' button-primary'}
                  >
                    <span className={'button-span-1'}>
                      <Translated Key='SERVICES_PAGE_send_request' />
                    </span>

                    <span className={'button-span-2 bs2-35-2'}>
                      <span
                        className={
                          classes['form-send-request-arrow'] +
                          ' arrow-right-black'
                        }
                      >
                        <img src={arrowImg} alt='arrow' />
                      </span>
                    </span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div
            className={
              classes['top-form-right'] +
              ` ${
                !modal ? ` col-xxl-2 ` : ` ${classes['top-right-modal']} `
              } col-xl-12`
            }
          >
            <div className={classes['top-form-right-content']}>
              <img src={road2Img} alt='road' className={classes['road-img']} />
              <div className={classes['yellow-overlay']}></div>
              <h5>
                <Translated Key='SERVICES_PAGE_schedule_conversation' />
              </h5>
              <p>
                <Translated Key='SERVICES_PAGE_schedule_conversation_text' />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DeliveryForm
