import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";
import CloseButton from "react-bootstrap/CloseButton";
import classes from "./FormModal.module.scss";
import arrowImg from "../../assets/images/icons/arrow-right.png";
import bp1Img from '../../assets/images/blueprints/bp-1.png';
import DeliveryForm from "./DeliveryForm";

const FormModal = ({ openModal, handleClose }) => {
  const close = () => handleClose();
  return (
    <Modal
      show={openModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      scrollable={true}
      size='xl'
      className={classes["card-preview-modal"] + " card-preview-modal"}
    >
      <CloseButton
        className={classes["close-button"]}
        aria-label="Hide"
        onClick={close}
      />
      <Modal.Body className={classes['body']}>
        <DeliveryForm banner={true} className={classes['modal-form']} modal={true}/>
      </Modal.Body>
    </Modal>
  );
};

export default FormModal;
