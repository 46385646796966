import classes from "../assets/scss/Reference.module.scss"
import Header2 from "../components/layout/Header2"
import siemensImg from "../assets/images/partners/siemens.png"
import hemofarmImg from "../assets/images/partners/hemofarm.png"
import airSerbiaImg from "../assets/images/partners/air-serbia.png"
import mindGroupImg from "../assets/images/partners/mind-group.png"
import hydroImg from "../assets/images/partners/hydro.png"
import wurthImg from "../assets/images/partners/wurth.png"
import ammImg from "../assets/images/partners/amm.png"
import transtradingImg from "../assets/images/partners/transtrading.png"
import PartnerContainer from "../components/layout/PartnerContainer"
import Translated from "../context/state"
const ReferencePage = () => {
  return (
    <>
      <Header2 title="HEADER_references" />
      <div className={classes["container"]}>
        <span className={classes["sub-heading"]}>
          <Translated Key="REFERENCES_PAGE_title" />
          <br />
          <span className={classes["partner-rect"] + " rect-secondary"}></span>
          <Translated Key="REFERENCES_PAGE_title2" />
        </span>
        <div className={classes["partners-container"]}>
          <PartnerContainer
            className={classes["partner"]}
            source={mindGroupImg}
          />

          <PartnerContainer className={classes["partner"]} source={ammImg} />

          <PartnerContainer
            className={classes["partner"]}
            source={siemensImg}
          />

          <PartnerContainer
            className={classes["partner"]}
            source={transtradingImg}
          />

          <PartnerContainer className={classes["partner"]} source={hydroImg} />
          <PartnerContainer className={classes["partner"]} source={wurthImg} />
          <PartnerContainer
            className={classes["partner"]}
            source={airSerbiaImg}
          />

          <PartnerContainer
            className={classes["partner"]}
            source={hemofarmImg}
          />
        </div>
        {/* <button className={classes["read-more"] + " button-primary"}>
          <span
            className={"button-span-1"}
            onClick={() => navigate("/case-study")}
          >
            <Translated Key="view_all_case_studies"/>
          </span>
          <span className={"button-span-2 bs2-30"}>
            <span className={classes["read-more-arrow"] + " arrow-right-black"}>
              <img src={arrowImg} alt="arrow" />
            </span>
          </span>
        </button> */}
      </div>
    </>
  )
}

export default ReferencePage
