import classes from "../assets/scss/BlogPage.module.scss"
import Header2 from "../components/layout/Header2"
import Translated from "../context/state"
import BlogPostBox from "../components/UI/BlogPostBox"

//BLOG POST IMAGES
import iso from "../assets/images/blogPosts/iso1.png"
import promena from "../assets/images/blogPosts/promena1.png"
import mind_park1 from "../assets/images/blogPosts/mind_park1.png"

const data = [
  {
    title: "BLOG_POST_1_title_short",
    description: "BLOG_POST_1_desc_short",
    image: iso,
  },
  {
    title: "BLOG_POST_2_title_short",
    description: "BLOG_POST_2_desc_short",
    image: promena,
  },
  {
    title: "BLOG_POST_3_title_short",
    description: "BLOG_POST_3_desc_short",
    image: mind_park1,
  },
]

const BlogPage = () => {
  return (
    <>
      <Header2 title={"HEADER_news"} />
      <div className={classes["container"]}>
        <h2>
          <Translated Key="NEWS_PAGE_title" />
          <br />
          <span className={classes["blog-rect"] + " rect-secondary"} />
          <Translated Key="NEWS_PAGE_title2" />
        </h2>
        <div className={classes["posts-row"] + " row"}>
          {data.map((post, index) => {
            return (
              <div
                className={classes["posts-col"] + " col-xl-4 col-md-6"}
                key={index}
              >
                <BlogPostBox
                  title={post.title}
                  description={post.description}
                  image={post.image}
                  id={index.toString()}
                />
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default BlogPage
