import linkedin from "../../assets/images/icons/linkedin.png"
import ytImg from "../../assets/images/icons/youtube-logotype.png"
import logoImg from "../../assets/images/logos/logo_2.png"
import { Link, useLocation } from "react-router-dom"
import arrowImg from "../../assets/images/icons/arrow-right.png"
import classes from "./Footer.module.scss"
import DeliveryForm from "./DeliveryForm"
import MyGoogleMap from "../MyGoogleMap"
import { format } from 'date-fns';
import ArrowDropdown from "../UI/ArrowDropdown"
import Translated from "../../context/state"
import { useCallback, useState } from "react"
import { apiHandler } from "../../api/api"
import { GoogleReCaptcha } from "react-google-recaptcha-v3"


const Footer = () => {
  const [formData, setFormData] = useState({
    address: "",
  })
  const [message, setMessage] = useState(null)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [token, setToken] = useState()
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)
  const currentYear = format(new Date(), 'yyyy');
  const api = apiHandler()

  const formChangeHandler = ({ target }) => {
    setFormData({ ...formData, [target.name]: target.value })
  }

  const verifyCaptcha = useCallback((token) => {
    setToken(token)
  }, [])

  const formSubmit = () => {
    if (!isLoading) {
      setIsLoading(true)
      setRefreshReCaptcha((r) => !r)
      api
        .post(`contact-form/newsletter`, { ...formData, captcha_token: token })
        .then((response) => {
          console.log(response)
          if (response?.success) {
            setMessage(response.message)
            setIsError(false)
            setFormData({
              address: "",
            })
          } else {
            setMessage(response.message)
            setIsError(true)
          }

          setIsLoading(false)
        })
        .catch((error) => {
          setMessage(Object.values(error.response.data.message)[0][0])
          setIsError(true)
          setIsLoading(false)
        })
    }
  }

  const location = useLocation()
  return (
    <>
      <GoogleReCaptcha
        onVerify={verifyCaptcha}
        refreshReCaptcha={refreshReCaptcha}
      />
      {location.pathname !== "/" && (
        <DeliveryForm className={classes["footer-form"]} banner={true} />
      )}
      <footer className={`${classes["footer-container"]}`}>
        <div className={classes["footer-top"] + " row"}>
          <div className={classes["footer-top-left"] + " col-xxl-7"}>
            <div className={"row"}>
              <div className={classes["footer-top-left-col"] + " col-lg-4"}>
                <img
                  src={logoImg}
                  alt="logo"
                  className={classes["footer-logo-img"]}
                />
                <h2 className={classes["footer-top-left-heading"]}>
                  <span
                    className={classes["footer-rect"] + " rect-secondary"}
                  ></span>
                  Čačak
                </h2>
                <p className={classes["footer-top-about-firm-2"]}>
                  Gornji Prijevorski put 33, 32205 Trbušani, Čačak, Srbija
                </p>

                <h2 className={classes["footer-top-left-heading"]}>
                  <span
                    className={classes["footer-rect"] + " rect-secondary"}
                  ></span>
                  Kragujevac
                </h2>
                <p className={classes["footer-top-about-firm-2"]}>
                  Aleja Milanović bb, 34325 (Mind Park), Srbija
                </p>
                <h2 className={classes["footer-top-left-heading"]}>
                  <span
                    className={classes["footer-rect"] + " rect-secondary"}
                  ></span>
                  Beograd
                </h2>
                <p className={classes["footer-top-about-firm-2"]}>
                  B W Aurora, Bulevar Vudroa Vilsona 8v/903, 11000 Beograd,
                  Srbija
                </p>
                <h2 className={classes["footer-top-left-heading"]}>
                  <span
                    className={classes["footer-rect"] + " rect-secondary"}
                  ></span>
                  Ljubljana
                </h2>
                <p className={classes["footer-top-about-firm-2"]}>
                  Letališka cesta 32j, 1000 Ljubljana, Slovenija
                </p>
              </div>
              <div
                className={
                  classes["footer-top-left-col"] +
                  " col-lg-4 " +
                  classes["footer-top-left-col-middle"]
                }
              >
                <h2 className={classes["footer-top-left-heading"]}>
                  <span
                    className={classes["footer-rect"] + " rect-secondary"}
                  ></span>
                  <Translated Key="FOOTER_info" />
                </h2>
                <ul className={classes["information-list"]}>
                  <li>
                    <Link to="/o-nama">
                      <Translated Key="FOOTER_about_us" />
                    </Link>
                  </li>
                  <li>
                    <a href="https://mobileweb.cvs-mobile.com/">
                      <Translated Key="FOOTER_track_your_cargo" />
                    </a>
                  </li>
                  <li>
                    <Link to="/aktuelno">
                      <Translated Key="FOOTER_news" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/reference">
                      <Translated Key="FOOTER_references" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/kontakt">
                      <Translated Key="FOOTER_contact" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/uslovi#uslovi">
                      <Translated Key="FOOTER_terms_conditions" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/uslovi#politika">
                      <Translated Key="FOOTER_privacy_policy" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/uslovi#kolacici">
                      <Translated Key="FOOTER_cookies_policy" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/politika-ims">
                      <Translated Key="IMS_Politics" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/information-security">
                      <Translated Key="security_politics" />
                    </Link>
                  </li>
                </ul>
                <ArrowDropdown
                  title={"FOOTER_info"}
                  className={classes["no-arrow-dropdown"]}
                >
                  <ul className={classes["information-list"]}>
                    <li>
                      <Link to="/o-nama">
                        <Translated Key="FOOTER_about_us" />
                      </Link>
                    </li>
                    <li>
                      <a href="https://mobileweb.cvs-mobile.com/">
                        <Translated Key="FOOTER_track_your_cargo" />
                      </a>
                    </li>
                    <li>
                      <Link to="/aktuelno">
                        <Translated Key="FOOTER_news" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/reference">
                        <Translated Key="FOOTER_references" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/kontakt">
                        <Translated Key="FOOTER_contact" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/uslovi#uslovi">
                        <Translated Key="FOOTER_terms_conditions" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/uslovi#politika">
                        <Translated Key="FOOTER_privacy_policy" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/uslovi#kolacici">
                        <Translated Key="FOOTER_cookies_policy" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/politika-ims">
                        <Translated Key="IMS_Politics" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/information-security">
                        <Translated Key="security_politics" />
                      </Link>
                    </li>
                  </ul>
                </ArrowDropdown>
              </div>
              <div className={classes["footer-top-left-col"] + " col-lg-4"}>
                <h2 className={classes["footer-top-left-heading"]}>
                  <span
                    className={classes["footer-rect"] + " rect-secondary"}
                  ></span>
                  <Translated Key="FOOTER_newsletter" />
                </h2>
                <p className={classes["footer-top-about-firm-3"]}>
                  <Translated key="FOOTER_newsletter_text" />
                </p>
                <div
                  className={classes["newsletter-input"] + " float-input-div"}
                >
                  <input
                    type="text"
                    value={formData.address}
                    onChange={formChangeHandler}
                    name="address"
                  />
                  <label>
                    <Translated Key="FOOTER_write_your_email" />
                  </label>
                </div>
                <div className={" float-input-div"}>
                  {message && (
                    <p
                      style={{
                        color: isError ? "red" : "green",
                        background: "white",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      <Translated Key={message} />
                    </p>
                  )}
                </div>
                <button
                  className={classes["form-send-request"] + " button-secondary"}
                  onClick={formSubmit}
                >
                  {!isLoading ? (
                    <span className={"button-span-1"}>
                      <Translated Key="FOOTER_send_request" />
                    </span>
                  ) : (
                    <span className={"button-span-1"}>
                      <Translated Key="sending" />
                    </span>
                  )}
                  <span className={"button-span-2 bs2-35-2"}>
                    <span
                      className={
                        classes["form-send-request-arrow"] +
                        " arrow-right-black"
                      }
                    >
                      <img src={arrowImg} alt="arrow" />
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className={classes["footer-top-right"] + " col-xxl-5"}>
            <MyGoogleMap
              latValue={44.45070117983443}
              lngValue={18.956449647309284}
            />
          </div>
        </div>
        <div className={classes["footer-bottom"]}>
          <div className={classes["footer-bottom-content"]}>
            <p className={classes["footer-bottom-text"]}>
              &copy; {currentYear} <Translated Key="FOOTER_all_rights_reserved" /> |
              steftrans.com Powered by{" "}
              <a href="https://croonus.com">Croonus Technologies</a>
            </p>
            <div className={classes["footer-bottom-icons"]}>
              <a
                href="https://www.linkedin.com/company/stef-trans-doo/"
                target="_blank"
                rel="noreferrer"
                className={classes["footer-bottom-icon-container"]}
              >
                <img className={"h-100"} alt="footer-icon" src={linkedin} />
              </a>

              <a
                href="https://youtube.com"
                target="_blank"
                rel="noreferrer"
                className={classes["footer-bottom-icon-container"]}
              >
                <img className={"h-100"} alt="footer-icon" src={ytImg} />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
