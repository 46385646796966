import * as ReactDOMClient from "react-dom/client"
import "./assets/scss/custom.scss"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { ScrollToTop } from "./helpers/functions"
import { AppWrapper } from "./context/state"

const container = document.getElementById("root")

// Create a root.
const root = ReactDOMClient.createRoot(container)
const local = "http://192.168.1.19:8000/api/"
const server = "https://api.steftrans.croonus.com/api/"
localStorage.setItem("api", process.env.REACT_APP_URL || server)

root.render(
  <BrowserRouter>
    <AppWrapper>
      <ScrollToTop />
      <App />
    </AppWrapper>
  </BrowserRouter>
)
