import classes from "../assets/scss/TermsOfUsePage.module.scss"
import Header2 from "../components/layout/Header2"
import { Link } from "react-router-dom"
import Translated from "../context/state"
const TermsOfUsePage = () => {
  return (
    <>
      <Header2 title={`FOOTER_privacy_policy`} />
      <div className={classes["container"]}>
        <h2>
          <Translated Key="TermsOfUse_title" />
          <br />
          <span className={classes["terms-rect"] + " rect-secondary"}></span>
          <Translated Key="TermsOfUse_title2" />
        </h2>
        <div className={classes["content"]}>
          <h6>
            <Translated Key="TermsOfUse_p1" />
          </h6>
          <h4>
            <span id="politika" className={classes["title-divider"]}></span>
            <span
              className={classes["terms-small-rect"] + " rect-secondary"}
            ></span>
            <Translated Key="TermsOfUse_subtitle" />
          </h4>
          <div className={classes["paragraphs"]}>
            <p>
              <Translated Key="TermsOfUse_p2" />
            </p>
            <p>
              <Translated Key="TermsOfUse_p3" />
            </p>
            <p>
              <Translated Key="TermsOfUse_p4" />
            </p>
            <p>
              <Translated Key="TermsOfUse_p5" />
            </p>
            <ul>
              <li>
                <Translated Key="TermsOfUse_li1" />
              </li>
              <li>
                <Translated Key="TermsOfUse_li2" />
              </li>
              <li>
                <Translated Key="TermsOfUse_li3" />
              </li>
              <li>
                <Translated Key="TermsOfUse_li4" />
              </li>
              <li>
                <Translated Key="TermsOfUse_li5" />
              </li>
            </ul>
          </div>
          <h6>
            <Translated Key="TermsOfUse_p6_1" />
            <Link to="/"> www.steftrans.com </Link>
            <Translated Key="TermsOfUse_p6_2" />
          </h6>
          <h4>
            <span id="uslovi" className={classes["title-divider"]}></span>
            <span
              className={classes["terms-small-rect"] + " rect-secondary"}
            ></span>
            <Translated Key="TermsOfUse_subtitle2" />
          </h4>
          <div className={classes["paragraphs"]}>
            <p>
              <Translated Key="TermsOfUse_p7" />
            </p>
            <p>
              <Translated Key="TermsOfUse_p8" />
            </p>
            <ul>
              <li>
                <Translated Key="TermsOfUse_li6" />
              </li>
              <li>
                <Translated Key="TermsOfUse_li7" />
              </li>
            </ul>
            <p>
              <li>
                <Translated Key="TermsOfUse_p9" />
              </li>
            </p>
          </div>
          <h4>
            <span id="kolacici" className={classes["title-divider"]}></span>
            <span
              className={classes["terms-small-rect"] + " rect-secondary"}
            ></span>
            <Translated Key="TermsOfUse_subtitle3" />
          </h4>
          <div className={classes["cookie-paragraphs"]}>
            <p>
              <Translated Key="TermsOfUse_p10" />
            </p>
            <p>
              <Translated Key="TermsOfUse_p11" />
            </p>
            <h5>
              <Translated Key="TermsOfUse_subsubtitle" />
            </h5>
            <p>
              <Translated Key="TermsOfUse_p12" />
            </p>
            <ul>
              <li>
                <Translated Key="TermsOfUse_li8" />
              </li>
              <li>
                <Translated Key="TermsOfUse_li9" />
              </li>
              <li>
                <Translated Key="TermsOfUse_li10" />
              </li>
              <li>
                <Translated Key="TermsOfUse_li11" />
              </li>
            </ul>
            <h5>
              <Translated Key="TermsOfUse_subsubtitle2" />
            </h5>
            <p>
              <Translated Key="TermsOfUse_p13" />
            </p>
            <p>
              <Translated Key="TermsOfUse_p14" />
            </p>
            <p>
              <Translated Key="TermsOfUse_p15" />
            </p>
            <h5>
              <Translated Key="TermsOfUse_subsubtitle3" />
            </h5>
            <p>
              <Translated Key="TermsOfUse_p16" />
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default TermsOfUsePage
