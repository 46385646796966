import { useState } from 'react';
import Translated from '../../context/state';
import classes from './CookiePop.module.scss';
import chevron from '../../assets/images/icons/down-chevron.png';
import { Link } from 'react-router-dom';
const CookiePop = ({handleAccept}) => {
    const [showMore, setShowMore] = useState(false);
    return (
        <div className={classes['cookie-pop']}>
            <div className={classes['container']}>
                <div className={classes['heading-button']}>
                    <h6>
                        <Translated Key="COOKIES_heading"/>
                    </h6>
                    <button type='button' onClick={()=>setShowMore(prev=>!prev)}>
                        <img src={chevron} alt='chevron' className={`${classes['arrow']} ${showMore ? classes['arrow-up']: classes['arrow-down']}`}/>
                        <Translated Key={`${!showMore ? 'COOKIES_read_more': 'COOKIES_read_less'}`}/>
                    </button>
                </div>
                {showMore && <div className={classes['show-more']}>
                        <p>
                            <Link to='/'>STEFTRANS</Link> <Translated Key='COOKIES_p1'/>
                        </p>
                        <Link to='/uslovi/#kolacici' className={classes['more-info']}>Više informacija</Link>
                        <div className={classes['checkboxes-container']}>
                            <div className={classes['checkbox-div']}>
                                <input type='checkbox' id='neophodni' disabled={true} checked={true}/>
                                <label htmlFor='neophodni'>Neophodni</label>
                            </div>
                            <div className={classes['checkbox-div']}>
                                <input type='checkbox' id='analiticki'/>
                                <label htmlFor='analiticki'>Analitički</label>
                            </div>
                            <div className={classes['checkbox-div']} >
                                <input type='checkbox' id='oglasavacki'/>
                                <label htmlFor='oglasavacki'>Oglašavački</label>
                            </div>
                        </div>

                    </div>}
                <button className={classes['accept']} onClick={handleAccept}>
                    <Translated Key="COOKIES_accept"/>
                </button>
            </div>
        </div>
    );
}

export default CookiePop;