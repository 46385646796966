import classes from '../assets/scss/ContactPage.module.scss'
import Header2 from '../components/layout/Header2'
import arrowImg from '../assets/images/icons/arrow-right.png'
import MyGoogleMap from '../components/MyGoogleMap'
import Translated from '../context/state'
import { useCallback, useState } from 'react'
import { apiHandler } from '../api/api'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useLocation } from 'react-router-dom'

const ContactPage = () => {
  const { state } = useLocation()

  const [formData, setFormData] = useState({
    name: state?.contact_person ?? '',
    company: state?.company_name ?? '',
    address: state?.address ?? '',
    phone: state?.phone ?? '',
    message: '',
  })
  const [message, setMessage] = useState(null)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [token, setToken] = useState()
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)

  const api = apiHandler()

  const formChangeHandler = ({ target }) => {
    setFormData({ ...formData, [target.name]: target.value })
  }

  const verifyCaptcha = useCallback((token) => {
    setToken(token)
  }, [])

  const formSubmit = () => {
    if (!isLoading) {
      setIsLoading(true)
      setRefreshReCaptcha((r) => !r)
      api
        .post(`contact-form/main`, { ...formData, captcha_token: token })
        .then((response) => {
          console.log(response)
          if (response?.success) {
            setMessage(response.message)
            setIsError(false)
            setFormData({
              name: '',
              company: '',
              address: '',
              phone: '',
              message: '',
            })
          } else {
            setMessage(response.message)
            setIsError(true)
          }

          setIsLoading(false)
        })
        .catch((error) => {
          setMessage(Object.values(error.response.data.message)[0][0])
          setIsError(true)
          setIsLoading(false)
        })
    }
  }

  return (
    <>
      <GoogleReCaptcha
        onVerify={verifyCaptcha}
        refreshReCaptcha={refreshReCaptcha}
      />
      <Header2 title={'HEADER_contact'} />
      <div className={classes['container']}>
        <h2>
          <Translated Key='HEADER_contact' />
          <br />
          <span
            className={classes['contact-us-rect'] + ' rect-secondary'}
          ></span>
          <Translated Key='FOOTER_info' />.
        </h2>
        <div className={classes[''] + ' row'}>
          <div className={classes['left-side'] + ' col-lg-3'}>
            <div className={classes['left-side-content']}>
              <h4>
                <span
                  className={classes['city-rect'] + ' rect-secondary'}
                ></span>
                Čačak
              </h4>
              <p>
              Gornji Prijevorski Put 33, 32205 Trbušani, Čačak, Srbija
                <span>
                  +381 32 322-322 <span>/</span> +381 32 342-687
                </span>
                <span>office@steftrans.com</span>
              </p>
              <h4>
                <span
                  className={classes['city-rect'] + ' rect-secondary'}
                ></span>
                Kragujevac
              </h4>
              <p>
                Aleja Milanović bb, 34325 (Mind Park), Srbija
                <span>
                  +381 32 322-322 <span>/</span> +381 32 342-687
                </span>
              </p>
              <h4>
                <span
                  className={classes['city-rect'] + ' rect-secondary'}
                ></span>
                Beograd
              </h4>
              <p>
                B W Aurora, Bulevar Vudroa Vilsona 8v/903, 11000 Beograd, Srbija
                <span>
                  +381 32 322-322 <span>/</span> +381 32 342-687
                </span>
              </p>
              <h4>
                <span
                  className={classes['city-rect'] + ' rect-secondary'}
                ></span>
                Ljubljana
              </h4>
              <p>
                Letališka cesta 32j, 1000 Ljubljana, Slovenija
                <span>
                  +386 593 65821 <span>/</span> +386 593 65822
                </span>
                <span>+386 593 67250</span> <br />
                <span>office@steftrans.si</span>
              </p>
            </div>
          </div>
          <div className={classes['right-side'] + ' col-lg-9'}>
            <div className={classes['right-side-row'] + ' row'}>
              <div className={classes['right-side-col'] + ' col-md-6'}>
                <div className={'float-input-div'}>
                  <input
                    type='text'
                    value={formData.name}
                    onChange={formChangeHandler}
                    name='name'
                  />
                  <label>
                    <Translated Key='CONTACT_name_and_last_name' />:
                  </label>
                </div>
              </div>
              <div className={classes['right-side-col'] + ' col-md-6'}>
                <div className={'float-input-div'}>
                  <input
                    type='text'
                    value={formData.company}
                    onChange={formChangeHandler}
                    name='company'
                  />
                  <label>
                    <Translated Key='CONTACT_company' />:
                  </label>
                </div>
              </div>
              <div className={classes['right-side-col'] + ' col-md-6'}>
                <div className={'float-input-div'}>
                  <input
                    type='text'
                    value={formData.address}
                    onChange={formChangeHandler}
                    name='address'
                  />
                  <label>
                    <Translated Key='CONTACT_address' />:
                  </label>
                </div>
              </div>
              <div className={classes['right-side-col'] + ' col-md-6'}>
                <div className={'float-input-div'}>
                  <input
                    type='text'
                    value={formData.phone}
                    onChange={formChangeHandler}
                    name='phone'
                  />
                  <label>
                    <Translated Key='CONTACT_telephone' />:
                  </label>
                </div>
              </div>
              <div className={classes['right-side-col'] + ' col-12'}>
                <div className={'float-input-div'}>
                  <textarea
                    rows={6}
                    value={formData.message}
                    onChange={formChangeHandler}
                    name='message'
                  />
                  <label>
                    <Translated Key='CONTACT_message' />:
                  </label>
                </div>
              </div>
            </div>
            <div className={classes['right-side-col'] + ' col-12'}></div>
            <div className={classes['right-side-col'] + ' col-12'}>
              {message && (
                <p style={{ color: isError ? 'red' : 'green' }}>
                  <Translated Key={message} />
                </p>
              )}
            </div>

            <button
              className={classes['contact-button'] + ' button-primary'}
              onClick={formSubmit}
            >
              {!isLoading ? (
                <span className={'button-span-1'}>
                  <Translated Key='CONTACT_send_message' />
                </span>
              ) : (
                <span className={'button-span-1'}>
                  <Translated Key='sending' />
                </span>
              )}
              <span className={'button-span-2 bs2-35'}>
                <span
                  className={
                    classes['contact-button-arrow'] + ' arrow-right-black'
                  }
                >
                  <img src={arrowImg} alt='arrow' />
                </span>
              </span>
            </button>
          </div>
        </div>

        <div className={classes['map-container']}>
          <div id='mapRel' className={classes['mapRel']}></div>
          <MyGoogleMap
            latValue={44.32070117983443}
            lngValue={21.126449647309284}
          />
        </div>
      </div>
    </>
  )
}

export default ContactPage
