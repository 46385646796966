import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Translated, {
  Translate,
  useAppContext,
  UseTranslate,
} from "../../context/state"

const Main = styled("div")`
  cursor: pointer;
  height: 100%;
`

const DropDownContainer = styled("div")`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`

const DropDownHeader = styled("div")`
  margin-bottom: 0.8em;
  padding: 0.875rem 0;
  border: 1px solid var(--croonus-lightMedGray);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-radius: 10px;
  font-weight: 400;
  text-align: center;
  font-size: 1.0625rem;
  color: var(--croonus-black);
  background: #ffffff;
`

const DropDownListContainer = styled("div")``

const DropDownList = styled("ul")`
  max-height: 200px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  border: 1px solid var(--croonus-lightMedGray);
  border-radius: 10px;
  z-index: 10;
  width: 100%;
  top: 100%;
  position: absolute;
  background: #ffffff;
  color: var(--croonus-black);
  font-size: 1.0625rem;
  font-weight: 400;
`

const ListItem = styled("li")`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--croonus-lightMedGray);
  text-align: center;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: var(--primary-color);
    color: var(--croonus-white);
    transition: all 0.2s ease-in-out;
  }
  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`

const TextSpan = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 0.5rem;
  display: block;
  white-space: nowrap;
`
const CustomSelect = ({ options, open, onOpen, id, value, name, onChange }) => {
  const [isOpen, setIsOpen] = useState(open)
  const [selectedOption, setSelectedOption] = useState(value)
  const Translate = UseTranslate()
  const toggling = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    if (typeof onOpen === "function") {
      if (isOpen) {
        onOpen(id)
      }
    }
  }, [isOpen])

  const onOptionClicked = (value) => () => {
    onChange({ target: { name: name, value: Translate(value) } })
    setSelectedOption(value)
    setIsOpen(false)
  }

  useEffect(() => {
    if (!open) setIsOpen(open)
  }, [open])

  useEffect(() => {
    setSelectedOption(value)
  }, [value])
  return (
    <Main>
      <DropDownContainer>
        <DropDownHeader onClick={toggling}>
          <TextSpan>
            {<Translated Key={selectedOption} /> || (
              <span
                style={{ color: "var(--croonus-mediumGray)", fontSize: "1rem" }}
              >
                -- <Translated Key="choose" /> --
              </span>
            )}
          </TextSpan>
        </DropDownHeader>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
              {options.map((option) => (
                <ListItem onClick={onOptionClicked(option)} key={Math.random()}>
                  {<Translated Key={option} />}
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </Main>
  )
}
export default CustomSelect
