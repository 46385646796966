import classes from "../assets/scss/CaseStudyPage.module.scss"
import Header2 from "../components/layout/Header2"
import arrowImg from "../assets/images/icons/arrow-right.png"
import Slider1 from "../components/UI/Slider1"
const CaseStudyPage = () => {
  return (
    <>
      <Header2 title={"Case studies"} />
      <div className={classes["container"]}>
        <div className={classes["top-buttons"]}>
          <button className={"previous-button"}>
            <span className={"previous-button-span-left"}>
              <img src={arrowImg} alt="arrow" />
            </span>
            <span className={"previous-button-span-right"}>
              <span className={"previous-button-span-right-2"}>PRETHODNA</span>
              <span className={"previous-button-span-right-1"}>
                CaseStudies
              </span>
            </span>
          </button>
          <h2>
            Case <br />
            <span className={classes["top-buttons-rect"] + " rect-secondary"} />
            studies.
          </h2>
          <button className={"next-button"}>
            <span className={"next-button-span-left"}>
              <span className={"next-button-span-left-2"}>SLEDEĆA</span>
              <span className={"next-button-span-left-1"}>Case studies</span>
            </span>{" "}
            <span className={"next-button-span-right"}>
              <img src={arrowImg} alt="arrow" />
            </span>
          </button>
        </div>
        <div className={classes["paragraphs"]}>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            viverra libero et nisl sagittis eleifend. Praesent sed dignissim
            odio. Aenean facilisis fermentum magna sit amet pharetra. Proin
            justo mi, iaculis eu diam at, lacinia congue ex. Suspendisse quis
            rhoncus nulla. Duis sagittis turpis ac bibendum euismod. Nam sit
            amet lacus pulvinar, pellentesque purus in, sagittis lorem. Nullam
            euismod libero eget porta suscipit.
          </p>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            viverra libero et nisl sagittis eleifend. Praesent sed dignissim
            odio. Aenean facilisis fermentum magna sit amet pharetra. Proin
            justo mi, iaculis eu diam at, lacinia congue ex. Suspendisse quis
            rhoncus nulla. Duis sagittis turpis ac bibendum euismod. Nam sit
            amet lacus pulvinar, pellentesque purus in, sagittis lorem. Nullam
            euismod libero eget porta suscipit.
          </p>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            viverra libero et nisl sagittis eleifend. Praesent sed dignissim
            odio. Aenean facilisis fermentum magna sit amet pharetra. Proin
            justo mi, iaculis eu diam at, lacinia congue ex. Suspendisse quis
            rhoncus nulla. Duis sagittis turpis ac bibendum euismod. Nam sit
            amet lacus pulvinar, pellentesque purus in, sagittis lorem. Nullam
            euismod libero eget porta suscipit.
          </p>
        </div>
        <Slider1 />
      </div>
    </>
  )
}

export default CaseStudyPage
