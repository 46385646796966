import classes from "../assets/scss/TermsOfUsePage.module.scss"
import Header2 from "../components/layout/Header2"
import Translated from "../context/state"
const InformationSecurity = () => {
  return (
    <>
      <Header2 title={`security_politics`} />
      <div className={classes["container"]}>
        <h2>
          <Translated Key="Security_Heading1" />
          <br />
          <span className={classes["terms-rect"] + " rect-secondary"}></span>
          <Translated Key="Security_Heading2" />
        </h2>
        <div className={classes["content"]}>
          <div className={classes["paragraphs"]}>
            <Translated Key="security_text" parsed={true} />
          </div>
        </div>
      </div>
    </>
  )
}

export default InformationSecurity
