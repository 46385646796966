import classes from "./Header2.module.scss";
import roadBg from "../../assets/images/road.jpg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Translated from "../../context/state";
const Header2 = ({ title }) => {
  const location = useLocation();
  return (
    <div className={classes["container"]}>
      <h2>
        <Translated Key={title} />
      </h2>
      <div className={classes["breadcrumb"]}>
        <span>
          <Link to="/">
            <Translated Key="HEADER_homepage" />
          </Link>
        </span>
        <span>
          <Link to={`${location.pathname}`}>
            <Translated Key={title} />
          </Link>
        </span>
      </div>
      <div className={classes["background-image"]}>
        <img alt="background" src={roadBg} />
        <div className={classes["gray-overlay"]}></div>
      </div>
    </div>
  );
};

export default Header2;
