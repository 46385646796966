import classes from "../assets/scss/BlogPostPage.module.scss";
import Header2 from "../components/layout/Header2";
import arrowImg from "../assets/images/icons/arrow-right.png";
import threadImg from "../assets/images/icons/thread.png";
import Slider1 from "../components/UI/Slider1";
import { useLocation, useNavigate } from "react-router-dom";
import Translated from "../context/state";
import { useEffect } from "react";

//BLOG POST IMAGES
import post0 from "../assets/images/blogPosts/demo.jpeg";
import iso1 from "../assets/images/blogPosts/iso1.png";
import iso2 from "../assets/images/blogPosts/iso2.png";
import mind_park_1 from "../assets/images/blogPosts/mind_park1.png";
import mind_park_2 from "../assets/images/blogPosts/mind_park2.jpg";
import promena1 from "../assets/images/blogPosts/promena1.png";
import promena2 from "../assets/images/blogPosts/promena2.png";
import promena3 from "../assets/images/blogPosts/promena3.jpg";
import promena4 from "../assets/images/blogPosts/promena4.jpg";
import promena5 from "../assets/images/blogPosts/promena5.jpg";

const data = [
  {
    title: "BLOG_POST_1_title_long",
    description: "BLOG_POST_1_desc_long",
    images: [iso2],
  },
  {
    title: "BLOG_POST_2_title_long",
    description: "BLOG_POST_2_desc_long",
    images: [promena1, promena2],
  },
  {
    title: "BLOG_POST_3_title_long",
    description: "BLOG_POST_3_desc_long",
    images: [mind_park_1, mind_park_2],
  },
];

const BlogPostPage = () => {
  const navigate = useNavigate();
  const location = useLocation().pathname.split("/");
  const page = Number(location[location.length - 1]);

  useEffect(() => {
    if (data[page] === undefined) {
      navigate("../aktuelno/" + 0);
    }
  });
  const display =
    data[page] !== undefined
      ? data[page]
      : {
          title: "",
          description: "",
          images: [],
        };

  function handlePrev() {
    if (page === 0) {
      return;
    } else {
      navigate("../aktuelno/" + (page - 1));
    }
  }

  function handleNext() {
    if (page === data.length - 1) {
      return;
    } else {
      navigate("../aktuelno/" + (page + 1));
    }
  }
  return (
    <>
      <Header2 title={"Blog post"} />
      <div className={classes["container"]}>
        <div className={classes["top-buttons"]}>
          <button
            className={"previous-button"}
            onClick={handlePrev}
            disabled={page === 0}
          >
            <span className={"previous-button-span-left"}>
              <img src={arrowImg} alt="arrow" />
            </span>
            <span className={"previous-button-span-right"}>
              <span className={"previous-button-span-right-2"}>PRETHODNA</span>
              <span className={"previous-button-span-right-1"}>Blog post</span>
            </span>
          </button>
          <h2>
            <span className={classes["top-buttons-rect"] + " rect-secondary"} />
            <Translated Key={display.title} parsed />
          </h2>
          <button className={"next-button"} onClick={handleNext}>
            <span className={"next-button-span-left"}>
              <span className={"next-button-span-left-2"}>SLEDEĆA</span>
              <span className={"next-button-span-left-1"}>Blog post</span>
            </span>{" "}
            <span className={"next-button-span-right"}>
              <img src={arrowImg} alt="arrow" />
            </span>
          </button>
        </div>
        <div className={classes["paragraphs"]}>
          <Translated Key={display.description} parsed />
        </div>
        <Slider1 images={display.images} />
      </div>
    </>
  );
};

export default BlogPostPage;
