import classes from './Benefit.module.scss';
import dotBorderImg from '../../assets/images/benefits/dot-border.png'
const Benefit = (props) => {
    return (
        <div className={classes['container']+' '+props.className}>
            {props.children}
            <img className={classes['dot-border']} src={dotBorderImg} alt='dot-border'/>
        </div>
    );
}

export default Benefit;