import classes from "../assets/scss/AboutUsPage.module.scss";
import Header2 from "../components/layout/Header2";
import logoImg from "../assets/images/logos/logo_2.png";
import Translated from "../context/state";
const AboutUsPage = () => {
  return (
    <>
      <Header2 title={"HEADER_about_us"} />
      <div className={classes["container"] + " row"}>
        <div className={"col-xl-4 " + classes["not-middle"]}>
          <h4 className={classes["small-heading"]}>
            <span
              className={classes["about-rect-small"] + " rect-secondary"}
            ></span>
            <Translated Key="ABOUT_US_21_year" />
          </h4>
          <p className={classes["paragraph"]}>
            <Translated Key="ABOUT_US_21_year_text" />
          </p>
          <h4 className={classes["small-heading"]}>
            <span
              className={classes["about-rect-small"] + " rect-secondary"}
            ></span>
            <Translated Key="ABOUT_US_communication_key" />
          </h4>
          <p className={classes["paragraph"]}>
            <Translated Key="ABOUT_US_communication_key_text" />
          </p>
          <h4 className={classes["small-heading"]}>
            <span
              className={classes["about-rect-small"] + " rect-secondary"}
            ></span>
            <Translated Key="ABOUT_US_over_4000_satisfied" />
          </h4>
          <p className={classes["paragraph"]}>
            <Translated Key="ABOUT_US_over_4000_satisfied_text" />
          </p>
          <h4 className={classes["small-heading"]}>
            <span
              className={classes["about-rect-small"] + " rect-secondary"}
            ></span>
            <Translated Key="ABOUT_US_park_over_100_vehicles" />
          </h4>
          <p className={classes["paragraph"]}>
            <Translated Key="ABOUT_US_park_over_100_vehicles_text" />
          </p>
          <h4 className={classes["small-sub-heading"]}>
            {/* <span
              className={classes["about-rect-small"] + " rect-secondary"}
            ></span> */}
            <Translated Key="ABOUT_US_vehicles_park" />
          </h4>
          <div className={classes["paragraph"]}>
            <ul className={classes["margined-list"]}>
              <li>
                <Translated Key="ABOUT_US_park_over_100_vehicles_list1" />
              </li>
              <li>
                <Translated Key="ABOUT_US_park_over_100_vehicles_list2" />
              </li>
              <li>
                <Translated Key="ABOUT_US_park_over_100_vehicles_list3" />
              </li>
              <li>
                <Translated Key="ABOUT_US_park_over_100_vehicles_list4" />
              </li>
              <li>
                <Translated Key="ABOUT_US_park_over_100_vehicles_list5" />
              </li>
              <li>
                <Translated Key="ABOUT_US_park_over_100_vehicles_list6" />
              </li>
              <li>
                <Translated Key="ABOUT_US_park_over_100_vehicles_list7" />
              </li>
              <li>
                <Translated Key="ABOUT_US_park_over_100_vehicles_list8" />
              </li>
              <li>
                <Translated Key="ABOUT_US_park_over_100_vehicles_list9" />
              </li>
            </ul>
          </div>
        </div>
        <div className={classes["middle"] + " col-xl-4"}>
          <div className={classes["middle-content"]}>
            <h2>
              <Translated Key="ABOUT_US_history1"/>
              <br />
              <span
                className={classes["about-us-rect"] + " rect-secondary"}
              ></span>
              
              <Translated Key="ABOUT_US_history2"/>
            </h2>
            <p>
              <Translated Key="ABOUT_US_history_text"/>
            </p>
            <img src={logoImg} alt="trucks" />
          </div>
        </div>
        <div className={"col-xl-4 " + classes["not-middle"]}>
          <h4 className={classes["small-heading"]}>
            <span
              className={classes["about-rect-small"] + " rect-secondary"}
            ></span>
            <Translated Key="ABOUT_US_over_140_employees"/>
          </h4>
          <p className={classes["paragraph"]}>
              <Translated Key="ABOUT_US_over_140_employees_text"/>
          </p>
          <h4 className={classes["small-heading"]}>
            <span
              className={classes["about-rect-small"] + " rect-secondary"}
            ></span>
            <Translated Key="ABOUT_US_tech_investments"/>
          </h4>
          <p className={classes["paragraph"]}>
              <Translated Key="ABOUT_US_tech_investments_text"/>
          </p>
          <h4 className={classes["small-heading"]}>
            <span
              className={classes["about-rect-small"] + " rect-secondary"}
            ></span>
            <Translated Key="ABOUT_US_services_from_trust"/>
          </h4>
          <p className={classes["paragraph"]}>
              <Translated Key="ABOUT_US_services_from_trust_text1"/>
          </p>
          <div className={classes["paragraph"]}>
            <ul>
              <li><Translated Key="ABOUT_US_services_from_trust_list1"/></li>
              <li><Translated Key="ABOUT_US_services_from_trust_list2"/></li>
              <li><Translated Key="ABOUT_US_services_from_trust_list3"/></li>
              <li><Translated Key="ABOUT_US_services_from_trust_list4"/></li>
              <li><Translated Key="ABOUT_US_services_from_trust_list5"/></li>
              <li><Translated Key="ABOUT_US_services_from_trust_list6"/></li>
              <li><Translated Key="ABOUT_US_services_from_trust_list7"/></li>
              <li><Translated Key="ABOUT_US_services_from_trust_list8"/></li>
              <li><Translated Key="ABOUT_US_services_from_trust_list9"/></li>
              <li><Translated Key="ABOUT_US_services_from_trust_list10"/></li>
            </ul>
          </div>
          <p>
              <Translated Key="ABOUT_US_services_from_trust_text2"/>
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutUsPage;
