import classes from '../assets/scss/HomePage.module.scss'
import { useEffect, useRef, useState } from 'react'
import roadImg from '../assets/images/road.jpg'
import arrowImg from '../assets/images/icons/arrow-right.png'
import { Link, useNavigate } from 'react-router-dom'
import suitManImg from '../assets/images/banners/suit-man.png'
import ref1 from '../assets/images/banners/ref1.jpg'
import ref2 from '../assets/images/banners/ref2.JPG'
import ref3 from '../assets/images/banners/ref3.jpeg'
import DeliveryForm from '../components/layout/DeliveryForm'
import siemensImg from '../assets/images/partners/siemens.png'
import airSerbiaImg from '../assets/images/partners/air-serbia.png'
import mindGroupImg from '../assets/images/partners/mind-group.png'
import hydroImg from '../assets/images/partners/hydro.png'
import PartnerContainer from '../components/layout/PartnerContainer'
import Benefit from '../components/layout/Benefit'
import handshakeImg from '../assets/images/benefits/handshake.png'
import benefitLocImg from '../assets/images/benefits/location.png'
import packagesImg from '../assets/images/benefits/packages.png'
import toDoImg from '../assets/images/benefits/to-do.png'
import ellipseTruckImg from '../assets/images/icons/ellipse-truck.png'
import roadLocImg from '../assets/images/icons/road.png'
import mapImg from '../assets/images/banners/map.png'
import truckIconImg from '../assets/images/icons/truck.png'
import customersIconImg from '../assets/images/icons/customer.png'
import listingImg from '../assets/images/icons/listing.png'
import SpecsModal from '../components/layout/SpecsModal'
import ammImg from '../assets/images/partners/amm.png'
import transtradingImg from '../assets/images/partners/transtrading.png'
import { useScrollPosition, useWindowDimensions } from '../helpers/functions'
import Slider from 'react-slick'
import Translated from '../context/state'
import vangabarit from '../assets/images/servicesTrucks/vangabarit3.png'
/**
 * Home page of B2C.
 *
 * @author Aleksandar Ječmenić <jecmenic.aleksandar@croonus.com>
 */
const HomePage = () => {
  const navigate = useNavigate()
  const [showSpecs, setShowSpecs] = useState(false)
  const [activeTrailer, setActiveTrailer] = useState(1)
  const [counter1, setCounter1] = useState(0)
  const [counter2, setCounter2] = useState(0)
  const [counter3, setCounter3] = useState(0)
  const [counter4, setCounter4] = useState(0)
  /* const [prikolica45, setPrikolica45] = useState(4) */
  // useEffect(()=> {
  //   setTimeout(()=>{
  //     setPrikolica45(prev=>{
  //       if(prev===5)
  //         return 4;
  //       else {
  //         return 5;
  //       }

  //     });
  //   },4000);
  //   clearInterval();
  // },[prikolica45]);

  const scroll = useScrollPosition()
  const [shouldCount, setShouldCount] = useState(false)
  const { width } = useWindowDimensions()
  const trailerRef = useRef(null)
  const scrollToTrailer = () => trailerRef.current.scrollIntoView()
  const incrementer1 = () => {
    if (counter1 < 100 && shouldCount) {
      setTimeout(() => setCounter1((prev) => prev + 1), 10)
    }
  }
  const incrementer2 = () => {
    if (counter2 < 4000 && shouldCount) {
      setTimeout(() => setCounter2((prev) => prev + 50), 40)
    }
  }
  const incrementer3 = () => {
    if (counter3 < 15000 && shouldCount) {
      setTimeout(() => setCounter3((prev) => prev + 150), 25)
    }
  }
  const incrementer4 = () => {
    if (counter4 < 10000000 && shouldCount) {
      setTimeout(() => setCounter4((prev) => prev + 25000), 4)
    }
  }

  useEffect(() => {
    if (scroll > 3000 || (scroll > 5000 && width < 1000)) setShouldCount(true)
  }, [scroll])

  useEffect(() => {
    incrementer1()
  }, [counter1, shouldCount])
  useEffect(() => {
    incrementer2()
  }, [counter2, shouldCount])
  useEffect(() => {
    incrementer3()
  }, [counter3, shouldCount])
  useEffect(() => {
    incrementer4()
  }, [counter4, shouldCount])

  return (
    <>
      <SpecsModal
        openModal={showSpecs}
        handleClose={() => setShowSpecs(false)}
        activeTrailer={activeTrailer}
      />
      <div className={classes['bg-image-container']}>
        <img className={classes['bg-image']} alt='bg' src={roadImg} />
        <div className={classes['gray-overlay']}></div>
      </div>
      <div className={classes['homepage-top-content']}>
        <div className={classes['top-content-top']}>
          <h1>
            <Translated Key='HOMEPAGE_service_of' /> <br />
            <span>
              <span className={classes['rect-1'] + ' rect-primary'}></span>
              <span>
                <Translated Key='HOMEPAGE_of_trust' />
              </span>
              <span className={classes['rect-2'] + ' rect-secondary'}></span>
            </span>
          </h1>
          <div>
            <ul className={classes['top-heading-list']}>
              <li>
                <Translated Key='HOMEPAGE_vehicle_park_over_100' />
              </li>
              <li>
                <Translated Key='HOMEPAGE_over_20_exp' />
              </li>
              <li>
                <Translated Key='HOMAPAGE_over_4000_satisfied' />
              </li>
            </ul>
          </div>
          <div className={classes['homepage-top-content-buttons']}>
            <button
              className={classes['read-more'] + ' button-primary'}
              onClick={() => navigate('/o-nama')}
            >
              <span className={'button-span-1'}>
                <Translated Key='read_more' />
              </span>
              <span className={'button-span-2 bs2-35'}>
                <span
                  className={classes['read-more-arrow'] + ' arrow-right-black'}
                >
                  <img src={arrowImg} alt='arrow' />
                </span>
              </span>
            </button>
            <span className={classes['arrows'] + ' arrow-button'}>
              <button className={'arrow-left-white'}>
                <img src={arrowImg} alt='arrow' />
              </button>
              <button className={'arrow-right-white'}>
                <img src={arrowImg} alt='arrow' />
              </button>
            </span>
          </div>
        </div>

        <div className={classes['value-of-quote-container']}>
          <div id='VOQ'></div>
          <span className={classes['voq-value']}>
            <Translated Key='SERVICES_PAGE_transportation_costs' />
          </span>
          <h2 className={classes['voq-text']}>
            <Translated Key='FORM_know_price' /> <br />
            <span className={classes['rect-3'] + ' rect-secondary'}></span>
            <Translated Key='FORM_of_your_delivery' />
          </h2>
        </div>
        <img
          className={classes['home-page-truck-1-img']}
          alt='truck'
          src={vangabarit}
        />
      </div>
      <div className={classes['home-page-middle']}>
        <DeliveryForm />
        <div className={classes['home-page-partners'] + ' row'}>
          <div className={classes['home-page-partners-left'] + ' col-xl-5'}>
            <Slider
              slidesToShow={1}
              slidesToScroll={1}
              arrows={false}
              dots={true}
            >
              <div className={classes['single-profile']}>
                <div className={classes['profile-image-and-heading']}>
                  <div className={classes['circle-profile-image']}>
                    <img alt='profile' src={ref2} />
                  </div>
                  <h4>
                    <Translated Key='HOMEPAGE_word_of_3_title' />
                  </h4>
                </div>
                <p>
                  <Translated Key='HOMEPAGE_word_of_3_text' />
                </p>

                <h3>
                  <Translated Key='HOMEPAGE_word_of_3_author' />
                </h3>
                <span>
                  <Translated Key='HOMEPAGE_word_of_3_position' />
                </span>
              </div>
              <div className={classes['single-profile']}>
                <div className={classes['profile-image-and-heading']}>
                  <div className={classes['circle-profile-image']}>
                    <img alt='profile' src={ref1} />
                  </div>
                  <h4>
                    <Translated Key='HOMEPAGE_word_of_2_title' />
                  </h4>
                </div>
                <p>
                  <Translated Key='HOMEPAGE_word_of_2_text' />
                </p>

                <h3>
                  <Translated Key='HOMEPAGE_word_of_2_author' />
                </h3>
                <span>
                  <Translated Key='HOMEPAGE_word_of_2_position' />
                </span>
              </div>
              <div className={classes['single-profile']}>
                <div className={classes['profile-image-and-heading']}>
                  <div className={classes['circle-profile-image']}>
                    <img alt='profile' src={ref3} />
                  </div>
                  <h4>
                    <Translated Key='HOMEPAGE_word_of_1_title' />
                  </h4>
                </div>
                <p>
                  <Translated Key='HOMEPAGE_word_of_1_text' />
                </p>

                <h3>
                  <Translated Key='HOMEPAGE_word_of_1_author' />
                </h3>
                <span>
                  <Translated Key='HOMEPAGE_word_of_1_position' />
                </span>
              </div>
            </Slider>
          </div>
          <div className={classes['home-page-partners-right'] + ' col-xl-7'}>
            <span className={classes['primary-sub-heading-info']}>
              <Translated Key='HOMEPAGE_get_informed_about_partners' />
            </span>
            <span className={classes['sub-heading']}>
              <Translated Key='HOMEPAGE_our_long_time' />
              <br />
              <span
                className={classes['sub-rect-1'] + ' rect-secondary'}
              ></span>

              <Translated Key='HOMEPAGE_partners' />
            </span>
            <div className={classes['partners-container']}>
              <PartnerContainer
                className={classes['partner']}
                source={mindGroupImg}
              />

              <PartnerContainer
                className={classes['partner']}
                source={ammImg}
              />

              <PartnerContainer
                className={classes['partner']}
                source={siemensImg}
              />

              <PartnerContainer
                className={classes['partner']}
                source={transtradingImg}
              />

              <PartnerContainer
                className={classes['partner']}
                source={airSerbiaImg}
              />
              <PartnerContainer
                className={classes['partner']}
                source={hydroImg}
              />
              <Link to='/reference' className={classes['more-references']}>
                <Translated Key='HOMEPAGE_see_all' /> &gt;
              </Link>
            </div>
          </div>
        </div>
        <div className={classes['benefits-container'] + ' row'}>
          <span className={classes['sub-heading']}>
            <Translated Key='HOMEPAGE_proof_our' />
            <br />
            <span className={classes['sub-rect-1'] + ' rect-secondary'}></span>
            <Translated Key='HOMEPAGE_business' />
          </span>
          <Slider
            slidesToShow={width > 1399.98 ? 4 : width > 575.98 ? 2 : 1}
            dots={width > 1399.98 ? false : true}
            slidesToScroll={width > 575.98 ? 2 : 1}
          >
            <div className={classes['benefit-col']}>
              <Benefit className='col-3'>
                <img src={handshakeImg} alt='benefit' />
                <h4>
                  <span
                    className={classes['benefit-rect'] + ' rect-secondary'}
                  ></span>
                  <Translated Key='HOMEPAGE_coverage' />
                </h4>
                <p>
                  <Translated Key='HOMEPAGE_coverage_text' />
                </p>
              </Benefit>
            </div>
            <div className={classes['benefit-col']}>
              <Benefit className='col-3'>
                <img src={benefitLocImg} alt='benefit' />
                <h4>
                  <span
                    className={classes['benefit-rect'] + ' rect-secondary'}
                  ></span>

                  <Translated Key='HOMEPAGE_expediency' />
                </h4>
                <p>
                  <Translated Key='HOMEPAGE_expediency_text' />
                </p>
              </Benefit>
            </div>
            <div className={classes['benefit-col']}>
              <Benefit className='col-3'>
                <img src={packagesImg} alt='benefit' />
                <h4>
                  <Translated Key='HOMEPAGE_summed_deliveries1' />
                  <br />
                  <span
                    className={classes['benefit-rect'] + ' rect-secondary'}
                  ></span>
                  <Translated Key='HOMEPAGE_summed_deliveries2' />
                </h4>
                <p>
                  <Translated Key='HOMEPAGE_summed_deliveries_text' />
                </p>
              </Benefit>
            </div>
            <div className={classes['benefit-col']}>
              <Benefit className='col-3'>
                <img src={toDoImg} alt='benefit' />
                <h4>
                  <Translated Key='HOMEPAGE_gabarit_transport1' />
                  <br />
                  <span
                    className={classes['benefit-rect'] + ' rect-secondary'}
                  ></span>
                  <Translated Key='HOMEPAGE_gabarit_transport2' />
                </h4>

                <p>
                  <Translated Key='HOMEPAGE_gabarit_transport_text' />
                </p>
              </Benefit>
            </div>
          </Slider>
        </div>
        <div className={classes['deliver-bespoke-container'] + ' row'}>
          <div className={classes['deliver-bespoke-left'] + ' col-xxl-4'}>
            <span className={classes['primary-sub-heading-info']}>
              <Translated Key='HOMEPAGE_gabarit_trailer_info' />
            </span>
            <span className={classes['sub-heading']}>
              <Translated Key='HOMEPAGE_quality_delivered' />
              <br />
              <span
                className={classes['deliver-bespoke-rect'] + ' rect-secondary'}
              ></span>
              <Translated Key='HOMEPAGE_as_standard' />
            </span>
            <div className={classes['deliver-bespoke-buttons-container']}>
              <button
                className={
                  classes['deliver-bespoke-button'] +
                  ' button-primary ' +
                  (activeTrailer === 1 &&
                    classes['deliver-bespoke-button-active'])
                }
                onClick={() => {
                  if (width < 1399.98) scrollToTrailer()
                  setActiveTrailer(1)
                }}
              >
                <span className={'button-span-1'}>
                  <Translated Key='nooteboom_3' />
                </span>
                <span className={'button-span-2 bs2-30'}>
                  <span
                    className={
                      classes['read-more-arrow'] + ' arrow-right-black'
                    }
                  >
                    <img src={arrowImg} alt='arrow' />
                  </span>
                </span>
              </button>

              <button
                className={
                  classes['deliver-bespoke-button'] +
                  ' button-primary ' +
                  (activeTrailer === 4 &&
                    classes['deliver-bespoke-button-active'])
                }
                onClick={() => {
                  if (width < 1399.98) scrollToTrailer()
                  setActiveTrailer(4)
                }}
              >
                <span className={'button-span-1'}>
                  <Translated Key='nooteboom_4' />
                </span>
                <span className={'button-span-2 bs2-30'}>
                  <span
                    className={
                      classes['read-more-arrow'] + ' arrow-right-black'
                    }
                  >
                    <img src={arrowImg} alt='arrow' />
                  </span>
                </span>
              </button>

              <button
                className={
                  classes['deliver-bespoke-button'] +
                  ' button-primary ' +
                  (activeTrailer === 2 &&
                    classes['deliver-bespoke-button-active'])
                }
                onClick={() => {
                  if (width < 1399.98) scrollToTrailer()
                  setActiveTrailer(2)
                }}
              >
                <span className={'button-span-1'}>
                  <Translated Key='axle_platform' />
                </span>
                <span className={'button-span-2 bs2-30'}>
                  <span
                    className={
                      classes['read-more-arrow'] + ' arrow-right-black'
                    }
                  >
                    <img src={arrowImg} alt='arrow' />
                  </span>
                </span>
              </button>

              <button
                className={
                  classes['deliver-bespoke-button'] +
                  ' button-primary ' +
                  (activeTrailer === 8 &&
                    classes['deliver-bespoke-button-active'])
                }
                onClick={() => {
                  if (width < 1399.98) scrollToTrailer()
                  setActiveTrailer(8)
                }}
              >
                <span className={'button-span-1'}>
                  <Translated Key='axle_platform2' />
                </span>
                <span className={'button-span-2 bs2-30'}>
                  <span
                    className={
                      classes['read-more-arrow'] + ' arrow-right-black'
                    }
                  >
                    <img src={arrowImg} alt='arrow' />
                  </span>
                </span>
              </button>

              <button
                className={
                  classes['deliver-bespoke-button'] +
                  ' button-primary ' +
                  (activeTrailer === 3 &&
                    classes['deliver-bespoke-button-active'])
                }
                onClick={() => {
                  if (width < 1399.98) scrollToTrailer()
                  setActiveTrailer(3)
                }}
              >
                <span className={'button-span-1'}>
                  <Translated Key='low_rider' />
                </span>
                <span className={'button-span-2 bs2-30'}>
                  <span
                    className={
                      classes['read-more-arrow'] + ' arrow-right-black'
                    }
                  >
                    <img src={arrowImg} alt='arrow' />
                  </span>
                </span>
              </button>
              <button
                className={
                  classes['deliver-bespoke-button'] +
                  ' button-primary ' +
                  (activeTrailer === 5 &&
                    classes['deliver-bespoke-button-active'])
                }
                onClick={() => {
                  if (width < 1399.98) scrollToTrailer()
                  setActiveTrailer(5)
                }}
              >
                <span className={'button-span-1'}>
                  <Translated Key='krone_mega_liner' />
                </span>
                <span className={'button-span-2 bs2-30'}>
                  <span
                    className={
                      classes['read-more-arrow'] + ' arrow-right-black'
                    }
                  >
                    <img src={arrowImg} alt='arrow' />
                  </span>
                </span>
              </button>
            </div>
          </div>
          {/* start vehicles details */}
          <div className={classes['deliver-bespoke-right'] + ' col-xxl-8'}>
            <div
              className={classes['deliver-bespoke-slider']}
              style={{
                transform: `translateX(calc(-100% * ${activeTrailer - 1}))`,
              }}
              ref={trailerRef}
            >
              <div
                className={classes['deliver-bespoke-slider-item']}
                style={{ left: `0` }}
              >
                <div className={classes['deliver-bespoke-right-img']}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/prikolica1.png`}
                    alt='truck'
                  />
                </div>

                <div className={classes['deliver-bespoke-right-floater']}>
                  <img alt='truck' src={ellipseTruckImg} />
                  <div className={classes['trailer-specs'] + ' row'}>
                    <div className={classes['trailer-specs-col'] + ' col-sm-6'}>
                      <span className={classes['trailer-specs-span']}>
                        <span>
                          <Translated Key='width' />
                        </span>
                        <span>2.54m</span>
                      </span>
                      <span className={classes['trailer-specs-span']}>
                        <span>
                          <Translated Key='height' />
                        </span>
                        <span>2.2m</span>
                      </span>
                    </div>
                    <div className={classes['trailer-specs-col'] + ' col-sm-6'}>
                      <span className={classes['trailer-specs-span']}>
                        <span>
                          <Translated Key='length' />
                        </span>
                        <span>15.8m</span>
                      </span>
                      <span className={classes['trailer-specs-span']}>
                        <span>
                          <Translated Key='capacity' />
                        </span>
                        <span>38t</span>
                      </span>
                    </div>
                  </div>
                  <span
                    className={classes['view-all-cs']}
                    onClick={() => setShowSpecs(true)}
                  >
                    <span
                      className={
                        classes['view-all-cs-rect'] + ' rect-secondary'
                      }
                    ></span>
                    <Translated Key='HOMEPAGE_view_specification' />
                  </span>
                </div>
              </div>

              <div
                className={classes['deliver-bespoke-slider-item']}
                style={{ left: `100%` }}
              >
                <div className={classes['deliver-bespoke-right-img']}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/prikolica2.png`}
                    alt='truck'
                  />
                </div>

                <div className={classes['deliver-bespoke-right-floater']}>
                  <img alt='truck' src={ellipseTruckImg} />
                  <div className={classes['trailer-specs'] + ' row'}>
                    <div className={classes['trailer-specs-col'] + ' col-sm-6'}>
                      <span className={classes['trailer-specs-span']}>
                        <span>
                          <Translated Key='width' />
                        </span>
                        <span>2.55m</span>
                      </span>
                      <span className={classes['trailer-specs-span']}>
                        <span>
                          <Translated Key='height' />
                        </span>
                        <span>2.5m</span>
                      </span>
                    </div>
                    <div className={classes['trailer-specs-col'] + ' col-sm-6'}>
                      <span className={classes['trailer-specs-span']}>
                        <span>
                          <Translated Key='length' />
                        </span>
                        <span>20m</span>
                      </span>
                      <span className={classes['trailer-specs-span']}>
                        <span>
                          <Translated Key='capacity' />
                        </span>
                        <span>44t</span>
                      </span>
                    </div>
                  </div>
                  <span
                    className={classes['view-all-cs']}
                    onClick={() => setShowSpecs(true)}
                  >
                    <span
                      className={
                        classes['view-all-cs-rect'] + ' rect-secondary'
                      }
                    ></span>
                    <Translated Key='HOMEPAGE_view_specification' />
                  </span>
                </div>
              </div>

              <div
                className={classes['deliver-bespoke-slider-item']}
                style={{ left: `700%` }}
              >
                <div className={classes['deliver-bespoke-right-img']}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/prikolica2.png`}
                    alt='truck'
                  />
                </div>

                <div className={classes['deliver-bespoke-right-floater']}>
                  <img alt='truck' src={ellipseTruckImg} />
                  <div className={classes['trailer-specs'] + ' row'}>
                    <div className={classes['trailer-specs-col'] + ' col-sm-6'}>
                      <span className={classes['trailer-specs-span']}>
                        <span>
                          <Translated Key='width' />
                        </span>
                        <span>2.55m</span>
                      </span>
                      <span className={classes['trailer-specs-span']}>
                        <span>
                          <Translated Key='height' />
                        </span>
                        <span>2.5m</span>
                      </span>
                    </div>
                    <div className={classes['trailer-specs-col'] + ' col-sm-6'}>
                      <span className={classes['trailer-specs-span']}>
                        <span>
                          <Translated Key='length' />
                        </span>
                        <span>29m</span>
                      </span>
                      <span className={classes['trailer-specs-span']}>
                        <span>
                          <Translated Key='capacity' />
                        </span>
                        <span>44t</span>
                      </span>
                    </div>
                  </div>
                  <span
                    className={classes['view-all-cs']}
                    onClick={() => setShowSpecs(true)}
                  >
                    <span
                      className={
                        classes['view-all-cs-rect'] + ' rect-secondary'
                      }
                    ></span>
                    <Translated Key='HOMEPAGE_view_specification' />
                  </span>
                </div>
              </div>

              <div
                className={classes['deliver-bespoke-slider-item']}
                style={{ left: `300%` }}
              >
                <div className={classes['deliver-bespoke-right-img']}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/prikolica4.png`}
                    alt='truck'
                  />
                </div>

                <div className={classes['deliver-bespoke-right-floater']}>
                  <img alt='truck' src={ellipseTruckImg} />
                  <div className={classes['trailer-specs'] + ' row'}>
                    <div className={classes['trailer-specs-col'] + ' col-sm-6'}>
                      <span className={classes['trailer-specs-span']}>
                        <span>
                          <Translated Key='width' />
                        </span>
                        <span>2.55m</span>
                      </span>
                      <span className={classes['trailer-specs-span']}>
                        <span>
                          <Translated Key='height' />
                        </span>
                        <span>2.85m</span>
                      </span>
                    </div>
                    <div className={classes['trailer-specs-col'] + ' col-sm-6'}>
                      <span className={classes['trailer-specs-span']}>
                        <span>
                          <Translated Key='length' />
                        </span>
                        <span>15.8m</span>
                      </span>
                      <span className={classes['trailer-specs-span']}>
                        <span>
                          <Translated Key='capacity' />
                        </span>
                        <span>47t</span>
                      </span>
                    </div>
                  </div>
                  <span
                    className={classes['view-all-cs']}
                    onClick={() => setShowSpecs(true)}
                  >
                    <span
                      className={
                        classes['view-all-cs-rect'] + ' rect-secondary'
                      }
                    ></span>
                    <Translated Key='HOMEPAGE_view_specification' />
                  </span>
                </div>
              </div>

              <div
                className={classes['deliver-bespoke-slider-item']}
                style={{ left: `200%` }}
              >
                <div className={classes['deliver-bespoke-right-img']}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/prikolica3.png`}
                    alt='truck'
                  />
                </div>

                <div className={classes['deliver-bespoke-right-floater']}>
                  <img alt='truck' src={ellipseTruckImg} />
                  <div className={classes['trailer-specs'] + ' row'}>
                    <div className={classes['trailer-specs-col'] + ' col-sm-6'}>
                      <span className={classes['trailer-specs-span']}>
                        <span>
                          <Translated Key='width' />
                        </span>
                        <span>2.55m</span>
                      </span>
                      <span className={classes['trailer-specs-span']}>
                        <span>
                          <Translated Key='height' />
                        </span>
                        <span>2.3m</span>
                      </span>
                    </div>
                    <div className={classes['trailer-specs-col'] + ' col-sm-6'}>
                      <span className={classes['trailer-specs-span']}>
                        <span>
                          <Translated Key='length' />
                        </span>
                        <span>21.60m</span>
                      </span>
                      <span className={classes['trailer-specs-span']}>
                        <span>
                          <Translated Key='capacity' />
                        </span>
                        <span>47t</span>
                      </span>
                    </div>
                  </div>
                  <span
                    className={classes['view-all-cs']}
                    onClick={() => setShowSpecs(true)}
                  >
                    <span
                      className={
                        classes['view-all-cs-rect'] + ' rect-secondary'
                      }
                    ></span>
                    <Translated Key='HOMEPAGE_view_specification' />
                  </span>
                </div>
              </div>

              <div
                className={classes['deliver-bespoke-slider-item']}
                style={{ left: `400%` }}
              >
                <div className={classes['deliver-bespoke-right-img']}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/prikolica5.png`}
                    alt='truck'
                  />
                </div>

                <div className={classes['deliver-bespoke-right-floater']}>
                  <img alt='truck' src={ellipseTruckImg} />
                  <div className={classes['trailer-specs'] + ' row'}>
                    <div className={classes['trailer-specs-col'] + ' col-sm-6'}>
                      <span className={classes['trailer-specs-span']}>
                        <span>
                          <Translated Key='width' />
                        </span>
                        <span>2.50m</span>
                      </span>
                      <span className={classes['trailer-specs-span']}>
                        <span>
                          <Translated Key='height' />
                        </span>
                        <span>3.30m</span>
                      </span>
                    </div>
                    <div className={classes['trailer-specs-col'] + ' col-sm-6'}>
                      <span className={classes['trailer-specs-span']}>
                        <span>
                          <Translated Key='length' />
                        </span>
                        <span>13.6m</span>
                      </span>
                      <span className={classes['trailer-specs-span']}>
                        <span>
                          <Translated Key='capacity' />
                        </span>
                        <span>24t</span>
                      </span>
                    </div>
                  </div>
                  <span
                    className={classes['view-all-cs']}
                    onClick={() => setShowSpecs(true)}
                  >
                    <span
                      className={
                        classes['view-all-cs-rect'] + ' rect-secondary'
                      }
                    ></span>
                    <Translated Key='HOMEPAGE_view_specification' />
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/*end vehicles details  */}
        </div>

        <div className={classes['numbers-container']}>
          <div className={classes['numbers-container-heading']}>
            <span className={classes['primary-sub-heading-info']}>
              <Translated Key='HOMEPAGE_trust_proof' />
            </span>
            <span className={classes['sub-heading']}>
              <Translated Key='HOMEPAGE_together_logistics1' />
              <br />
              <span
                className={classes['numbers-rect'] + ' rect-secondary'}
              ></span>
              <Translated Key='HOMEPAGE_together_logistics2' />
            </span>
          </div>
          <div className={classes['map-container']}>
            <img alt='map' src={mapImg} />
            <div className={classes['map-benefits'] + ' row'}>
              <div className={classes['map-benefits-col'] + ' col-xl-3 col-6'}>
                <div className={classes['map-benefits-col-cloud']}>
                  <img src={truckIconImg} alt='map-benefit' />
                </div>
                <span className={classes['map-benefits-span-1']}>
                  {counter1}
                  {counter1 === 100 && '+'}
                </span>
                <span className={classes['map-benefits-span-2']}>
                  <Translated Key='HOMEPAGE_vehicles' />
                </span>
              </div>
              <div className={classes['map-benefits-col'] + ' col-xl-3 col-6'}>
                <div className={classes['map-benefits-col-cloud']}>
                  <img src={customersIconImg} alt='map-benefit' />
                </div>
                <span className={classes['map-benefits-span-1']}>
                  {counter2}
                  {counter2 === 4000 && '+'}
                </span>
                <span className={classes['map-benefits-span-2']}>
                  <Translated Key='HOMEPAGE_satisfied_clients' />
                </span>
              </div>
              <div className={classes['map-benefits-col'] + ' col-xl-3 col-6'}>
                <div className={classes['map-benefits-col-cloud']}>
                  <img src={listingImg} alt='map-benefit' />
                </div>
                <span className={classes['map-benefits-span-1']}>
                  {counter3}
                  {counter3 === 15000 && '+'}
                </span>
                <span className={classes['map-benefits-span-2']}>
                  <Translated Key='HOMEPAGE_transport_warrant' />
                </span>
              </div>
              <div className={classes['map-benefits-col'] + ' col-xl-3 col-6'}>
                <div className={classes['map-benefits-col-cloud']}>
                  <img src={roadLocImg} alt='map-benefit' />
                </div>
                <span className={classes['map-benefits-span-1']}>
                  {counter4}
                  {counter4 === 10000000 && '+'}
                </span>
                <span className={classes['map-benefits-span-2']}>
                  <Translated Key='HOMEPAGE_passed_kms' />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomePage
