import Header2 from '../components/layout/Header2';
/**
 * Page that is displayed when the route is not found.
 *
 * @author Aleksandar Ječmenić <jecmenic.aleksandar@croonus.com>
*/
const NotFoundPage = () => {
    return (
        <>
        <Header2 title={'HEADER_not_found'}/>
        </>
    );
}

export default NotFoundPage;