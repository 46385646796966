import React, { useCallback, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import CloseButton from 'react-bootstrap/CloseButton'
import classes from './TransportInfoModal.module.scss'
import arrowImg from '../../assets/images/icons/arrow-right.png'
import backImg from '../../assets/images/icons/backArrow.png'
import CustomSelect from './CustomSelect'
import Translated from '../../context/state'
import { apiHandler } from '../../api/api'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Link } from 'react-router-dom'

const TransportInfoModal = ({ openModal, handleClose, contactData }) => {
  const [option, setOption] = useState('none')
  const [open, setOpen] = useState(undefined)
  const [message, setMessage] = useState(null)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [token, setToken] = useState()
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)

  const verifyCaptcha = useCallback((token) => {
    setToken(token)
  }, [])

  const [formData, setFormData] = useState({
    type_of_vehicle: '',
    delivery_from_company_name: '',
    delivery_from_address: '',
    date_of_departure: '',
    export_clearance: '',
    delivery_from_contact_phone: '',

    delivery_to_company_name: '',
    delivery_to_address: '',
    arrival_date: '',
    import_clearance: '',
    delivery_to_contact_phone: '',

    type_of_goods: '',
    dimensions: '',
    package_type: '',
    dangerous_goods: '',
    cargo_insurance: '',
    fridge_needed: '',
  })
  const close = () => {
    handleClose()
    setFormData({
      type_of_vehicle: '',
      delivery_from_company_name: '',
      delivery_from_address: '',
      date_of_departure: '',
      export_clearance: '',
      delivery_from_contact_phone: '',

      delivery_to_company_name: '',
      delivery_to_address: '',
      arrival_date: '',
      import_clearance: '',
      delivery_to_contact_phone: '',

      type_of_goods: '',
      dimensions: '',
      package_type: '',
      dangerous_goods: '',
      cargo_insurance: '',
      fridge_needed: '',
    })
  }

  const api = apiHandler()

  const formChangeHandler = ({ target }) => {
    setFormData({ ...formData, [target.name]: target.value })
  }

  useEffect(() => {
    setMessage(null)
    setFormData({
      type_of_vehicle: '',
      delivery_from_company_name: '',
      delivery_from_address: '',
      date_of_departure: '',
      export_clearance: '',
      delivery_from_contact_phone: '',

      delivery_to_company_name: '',
      delivery_to_address: '',
      arrival_date: '',
      import_clearance: '',
      delivery_to_contact_phone: '',

      type_of_goods: '',
      dimensions: '',
      package_type: '',
      dangerous_goods: '',
      cargo_insurance: '',
      fridge_needed: '',
    })
  }, [option])

  const formSubmit = () => {
    if (!isLoading) {
      setIsLoading(true)
      setRefreshReCaptcha((r) => !r)
      let path = false
      switch (option) {
        case 'standard':
          path = 'contact-form/standard-transport'
          break
        case 'vangabarit':
          path = 'contact-form/vangabarit-transport'
          break
        default:
          path = false
          break
      }
      if (path) {
        api
          .post(path, { ...formData, ...contactData, captcha_token: token })
          .then((response) => {
            if (response?.success) {
              setMessage(response.message)
              setIsError(false)
              setFormData({
                type_of_vehicle: '',
                delivery_from_company_name: '',
                delivery_from_address: '',
                date_of_departure: '',
                export_clearance: '',
                delivery_from_contact_phone: '',

                delivery_to_company_name: '',
                delivery_to_address: '',
                arrival_date: '',
                import_clearance: '',
                delivery_to_contact_phone: '',

                type_of_goods: '',
                dimensions: '',
                package_type: '',
                dangerous_goods: '',
                cargo_insurance: '',
                fridge_needed: '',
              })
            } else {
              setMessage(response.message)
              setIsError(true)
            }
            setIsLoading(false)
          })
          .catch((error) => {
            setMessage(Object.values(error.response.data.message)[0][0])
            setIsError(true)
            setIsLoading(false)
          })
      }
    }
  }

  console.log(message)

  return (
    <Modal
      show={openModal}
      onHide={close}
      backdrop='static'
      keyboard={false}
      centered
      scrollable={true}
      size='xl'
      className={classes['card-preview-modal'] + ' card-preview-modal'}
      onClick={() => {
        setOpen(undefined)
      }}
    >
      <GoogleReCaptcha
        onVerify={verifyCaptcha}
        refreshReCaptcha={refreshReCaptcha}
      />
      <CloseButton
        className={option === 'none' ? classes['close-button'] : 'd-none'}
        aria-label='Hide'
        onClick={close}
      />
      <button
        type='button'
        onClick={() => setOption('none')}
        className={option !== 'none' ? classes['back-button'] : 'd-none'}
      >
        <img src={backImg} alt='back-img' />
      </button>
      <Modal.Body className={classes['body']}>
        {option === 'none' ? (
          <>
            <h6 className={classes['subheading']}>
              <Translated Key='SERVICES_PAGE_choose_transport_type_subtitle' />:
            </h6>
            <div className={classes['option-button-container'] + ' row'}>
              <div className={classes['option-button-col'] + ' col-xxl-4'}>
                <button
                  className={classes['choose-option'] + ' button-primary'}
                  onClick={() => setOption('standard')}
                >
                  <span className={'button-span-1'}>
                    <Translated Key='SERVICES_PAGE_choose_transport_type_type1_title' />
                  </span>
                  <span className={'button-span-2 bs2-35-2'}>
                    <span
                      className={
                        classes['choose-option-arrow'] + ' arrow-right-black'
                      }
                    >
                      <img src={arrowImg} alt='arrow' />
                    </span>
                  </span>
                </button>
                <div className={classes['cloud-paragraph']}>
                  <p>
                    <Translated Key='SERVICES_PAGE_choose_transport_type_type1_text' />
                  </p>
                </div>
              </div>
              <div className={classes['option-button-col'] + ' col-xxl-4'}>
                <button
                  className={classes['choose-option'] + ' button-primary'}
                  onClick={() => setOption('vangabarit')}
                >
                  <span className={'button-span-1'}>
                    <Translated Key='SERVICES_PAGE_choose_transport_type_type2_title' />
                  </span>
                  <span className={'button-span-2 bs2-35-2'}>
                    <span
                      className={
                        classes['choose-option-arrow'] + ' arrow-right-black'
                      }
                    >
                      <img src={arrowImg} alt='arrow' />
                    </span>
                  </span>
                </button>
                <div className={classes['cloud-paragraph']}>
                  <p>
                    <Translated Key='SERVICES_PAGE_choose_transport_type_type2_text' />
                  </p>
                </div>
              </div>

              <div className={classes['option-button-col'] + ' col-xxl-4'}>
                <Link
                  to='kontakt'
                  state={{ ...contactData }}
                  className={classes['choose-option']}
                >
                  <button
                    className={classes['choose-option'] + ' button-primary'}
                    onClick={() => close()}
                  >
                    <span className={'button-span-1'}>
                      <Translated Key='SERVICES_PAGE_choose_transport_type_contact_title' />
                    </span>
                    <span className={'button-span-2 bs2-35-2'}>
                      <span
                        className={
                          classes['choose-option-arrow'] + ' arrow-right-black'
                        }
                      >
                        <img src={arrowImg} alt='arrow' />
                      </span>
                    </span>
                  </button>
                </Link>
                <div className={classes['cloud-paragraph']}>
                  <p>
                    <Translated Key='SERVICES_PAGE_choose_transport_type_contact_text' />
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : option === 'standard' ? (
          <>
            <h6 className={classes['subheading']}>
              <Translated Key='MODAL_standard_heading' />
              {message && (
                <p style={{ color: isError ? 'red' : 'green' }}>
                  <Translated Key={message} />
                </p>
              )}
            </h6>
            <div className={classes['vehicle-type-row'] + ' row'}>
              <div className={classes['float-input-div-col'] + ' col-xxl-4'}>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <CustomSelect
                    options={[
                      'MODAL_vehicle_type_item1',
                      'MODAL_vehicle_type_item17',
                      'MODAL_vehicle_type_item16',
                      'MODAL_vehicle_type_item2',
                      'MODAL_vehicle_type_item3',
                      'MODAL_vehicle_type_item4',
                      'MODAL_vehicle_type_item5',
                      'MODAL_vehicle_type_item6',
                      'MODAL_vehicle_type_item7',
                    ]}
                    id={1}
                    open={open === 1}
                    onOpen={setOpen}
                    value={formData.type_of_vehicle}
                    name='type_of_vehicle'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='MODAL_vehicle_type' />:
                  </label>
                </div>
              </div>
            </div>
            <div className={classes['inputs-row'] + ' row'}>
              <div className={classes['float-input-div-col'] + ' col-xxl-4'}>
                <h6 className={classes['subheading']}>
                  <Translated Key='MODAL_data_about_load_place' />
                </h6>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <input
                    type='text'
                    value={formData.delivery_from_company_name}
                    name='delivery_from_company_name'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='SERVICES_PAGE_company_name' />:
                  </label>
                </div>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <input
                    type='text'
                    value={formData.delivery_from_address}
                    name='delivery_from_address'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='MODAL_load_address' />:
                  </label>
                </div>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <input
                    type='date'
                    value={formData.date_of_departure}
                    name='date_of_departure'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='MODAL_load_date' />:
                  </label>
                </div>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <CustomSelect
                    options={[
                      'MODAL_export_taxing_item1',
                      'MODAL_export_taxing_item2',
                    ]}
                    id={2}
                    open={open === 2}
                    onOpen={setOpen}
                    value={formData.export_clearance}
                    name='export_clearance'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='MODAL_export_taxing' />:
                  </label>
                </div>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <input
                    type='text'
                    value={formData.delivery_from_contact_phone}
                    name='delivery_from_contact_phone'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='MODAL_load_phone' />:
                  </label>
                </div>
              </div>
              <div className={classes['float-input-div-col'] + ' col-xxl-4'}>
                <h6 className={classes['subheading']}>
                  <Translated Key='MODAL_data_about_unload_place' />:
                </h6>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <input
                    type='text'
                    value={formData.delivery_to_company_name}
                    name='delivery_to_company_name'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='SERVICES_PAGE_company_name' />:
                  </label>
                </div>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <input
                    type='text'
                    value={formData.delivery_to_address}
                    name='delivery_to_address'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='MODAL_unload_address' />:
                  </label>
                </div>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <input
                    type='date'
                    value={formData.arrival_date}
                    name='arrival_date'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='MODAL_unload_date' />:
                  </label>
                </div>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <CustomSelect
                    options={[
                      'MODAL_export_taxing_item1',
                      'MODAL_export_taxing_item2',
                    ]}
                    id={3}
                    open={open === 3}
                    onOpen={setOpen}
                    value={formData.import_clearance}
                    name='import_clearance'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='MODAL_import_taxing' />:
                  </label>
                </div>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <input
                    type='text'
                    value={formData.delivery_to_contact_phone}
                    name='delivery_to_contact_phone'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='MODAL_unload_phone' />:
                  </label>
                </div>
              </div>
              <div className={classes['float-input-div-col'] + ' col-xxl-4'}>
                <h6 className={classes['subheading']}>
                  <Translated Key='MODAL_data_about_cargo' />:
                </h6>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <input
                    type='text'
                    value={formData.type_of_goods}
                    name='type_of_goods'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='MODAL_cargo_type' />:
                  </label>
                </div>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <input
                    type='text'
                    value={formData.dimensions}
                    name='dimensions'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='MODAL_dimensions' />:
                  </label>
                </div>
                <div className={classes['two-inputs'] + ' row'}>
                  <div
                    className={
                      'float-input-div col-6 ' + classes['input-container']
                    }
                  >
                    <CustomSelect
                      options={['MODAL_palette', 'MODAL_parcel', 'MODAL_other']}
                      id={14}
                      open={open === 14}
                      onOpen={setOpen}
                      value={formData.package_type}
                      name='package_type'
                      onChange={formChangeHandler}
                    />
                    <label>
                      <Translated Key='MODAL_package_type' />:
                    </label>
                  </div>
                  <div
                    className={
                      'float-input-div col-6 ' + classes['input-container']
                    }
                  >
                    <CustomSelect
                      options={['Yes', 'No']}
                      id={4}
                      open={open === 4}
                      onOpen={setOpen}
                      value={formData.dangerous_goods}
                      name='dangerous_goods'
                      onChange={formChangeHandler}
                    />
                    <label>
                      <Translated Key='MODAL_dangerous_cargo' />:
                    </label>
                  </div>
                </div>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <CustomSelect
                    options={['MODAL_cargo_item1', 'MODAL_cargo_item2']}
                    id={5}
                    open={open === 5}
                    onOpen={setOpen}
                    value={formData.cargo_insurance}
                    name='cargo_insurance'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='MODAL_cargo_insurence' />:
                  </label>
                </div>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <CustomSelect
                    options={['Yes', 'No']}
                    id={6}
                    open={open === 6}
                    onOpen={setOpen}
                    value={formData.fridge_needed}
                    name='fridge_needed'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='MODAL_frigo_needed' />:
                  </label>
                </div>
              </div>
            </div>

            <button
              className={classes['send-request'] + ' button-primary'}
              onClick={() => {
                formSubmit()
              }}
            >
              {!isLoading ? (
                <span className={'button-span-1'}>
                  <Translated Key='SERVICES_PAGE_send_request' />
                </span>
              ) : (
                <span className={'button-span-1'}>
                  <Translated Key='sending' />
                </span>
              )}
              <span className={'button-span-2 bs2-35-2'}>
                <span
                  className={
                    classes['choose-option-arrow'] + ' arrow-right-black'
                  }
                >
                  <img src={arrowImg} alt='arrow' />
                </span>
              </span>
            </button>
          </>
        ) : option === 'vangabarit' ? (
          <>
            <h6 className={classes['subheading']}>
              <Translated Key='MODAL_oversized_heading' />
              {message && (
                <p style={{ color: isError ? 'red' : 'green' }}>
                  <Translated Key={message} />
                </p>
              )}
            </h6>

            <div className={classes['vehicle-type-row'] + ' row'}>
              <div className={classes['float-input-div-col'] + ' col-xxl-4'}>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <CustomSelect
                    options={[
                      'MODAL_vehicle_type_item8',
                      'MODAL_vehicle_type_item9',
                      'MODAL_vehicle_type_item10',
                      'MODAL_vehicle_type_item11',
                      'MODAL_vehicle_type_item12',
                      'MODAL_vehicle_type_item13',
                    ]}
                    id={7}
                    open={open === 7}
                    onOpen={setOpen}
                    value={formData.type_of_vehicle}
                    name='type_of_vehicle'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='MODAL_vehicle_type' />:
                  </label>
                </div>
              </div>
            </div>
            <div className={classes['inputs-row'] + ' row'}>
              <div className={classes['float-input-div-col'] + ' col-xxl-4'}>
                <h6 className={classes['subheading']}>
                  <Translated Key='MODAL_data_about_load_place' />
                </h6>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <input
                    type='text'
                    value={formData.delivery_from_company_name}
                    name='delivery_from_company_name'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='SERVICES_PAGE_company_name' />:
                  </label>
                </div>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <input
                    type='text'
                    value={formData.delivery_from_address}
                    name='delivery_from_address'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='MODAL_load_address' />:
                  </label>
                </div>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <input
                    type='date'
                    value={formData.date_of_departure}
                    name='date_of_departure'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='MODAL_load_date' />:
                  </label>
                </div>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <CustomSelect
                    options={[
                      'MODAL_export_taxing_item1',
                      'MODAL_export_taxing_item2',
                    ]}
                    id={2}
                    open={open === 2}
                    onOpen={setOpen}
                    value={formData.export_clearance}
                    name='export_clearance'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='MODAL_export_taxing' />:
                  </label>
                </div>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <input
                    type='text'
                    value={formData.delivery_from_contact_phone}
                    name='delivery_from_contact_phone'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='MODAL_load_phone' />:
                  </label>
                </div>
              </div>
              <div className={classes['float-input-div-col'] + ' col-xxl-4'}>
                <h6 className={classes['subheading']}>
                  <Translated Key='MODAL_data_about_unload_place' />:
                </h6>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <input
                    type='text'
                    value={formData.delivery_to_company_name}
                    name='delivery_to_company_name'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='SERVICES_PAGE_company_name' />:
                  </label>
                </div>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <input
                    type='text'
                    value={formData.delivery_to_address}
                    name='delivery_to_address'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='MODAL_unload_address' />:
                  </label>
                </div>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <input
                    type='date'
                    value={formData.arrival_date}
                    name='arrival_date'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='MODAL_unload_date' />:
                  </label>
                </div>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <CustomSelect
                    options={[
                      'MODAL_export_taxing_item1',
                      'MODAL_export_taxing_item2',
                    ]}
                    id={3}
                    open={open === 3}
                    onOpen={setOpen}
                    value={formData.import_clearance}
                    name='import_clearance'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='MODAL_import_taxing' />:
                  </label>
                </div>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <input
                    type='text'
                    value={formData.delivery_to_contact_phone}
                    name='delivery_to_contact_phone'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='MODAL_unload_phone' />:
                  </label>
                </div>
              </div>
              <div className={classes['float-input-div-col'] + ' col-xxl-4'}>
                <h6 className={classes['subheading']}>
                  <Translated Key='MODAL_data_about_cargo' />:
                </h6>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <input
                    type='text'
                    value={formData.type_of_goods}
                    name='type_of_goods'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='MODAL_cargo_type' />:
                  </label>
                </div>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <input
                    type='text'
                    value={formData.dimensions}
                    name='dimensions'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='MODAL_dimensions' />:
                  </label>
                </div>
                <div className={classes['two-inputs'] + ' row'}>
                  <div
                    className={
                      'float-input-div col-6 ' + classes['input-container']
                    }
                  >
                    <CustomSelect
                      options={['MODAL_palette', 'MODAL_parcel', 'MODAL_other']}
                      id={14}
                      open={open === 14}
                      onOpen={setOpen}
                      value={formData.package_type}
                      name='package_type'
                      onChange={formChangeHandler}
                    />
                    <label>
                      <Translated Key='MODAL_package_type' />:
                    </label>
                  </div>
                  <div
                    className={
                      'float-input-div col-6 ' + classes['input-container']
                    }
                  >
                    <CustomSelect
                      options={['Yes', 'No']}
                      id={4}
                      open={open === 4}
                      onOpen={setOpen}
                      value={formData.dangerous_goods}
                      name='dangerous_goods'
                      onChange={formChangeHandler}
                    />
                    <label>
                      <Translated Key='MODAL_dangerous_cargo' />:
                    </label>
                  </div>
                </div>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <CustomSelect
                    options={['MODAL_cargo_item1', 'MODAL_cargo_item2']}
                    id={5}
                    open={open === 5}
                    onOpen={setOpen}
                    value={formData.cargo_insurance}
                    name='cargo_insurance'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='MODAL_cargo_insurence' />:
                  </label>
                </div>
                <div
                  className={'float-input-div ' + classes['input-container']}
                >
                  <CustomSelect
                    options={['Yes', 'No']}
                    id={6}
                    open={open === 6}
                    onOpen={setOpen}
                    value={formData.fridge_needed}
                    name='fridge_needed'
                    onChange={formChangeHandler}
                  />
                  <label>
                    <Translated Key='MODAL_frigo_needed' />:
                  </label>
                </div>
              </div>
            </div>

            <button
              className={classes['send-request'] + ' button-primary'}
              onClick={formSubmit}
            >
              <span className={'button-span-1'}>Pošaljite zahtev</span>
              <span className={'button-span-2 bs2-35-2'}>
                <span
                  className={
                    classes['choose-option-arrow'] + ' arrow-right-black'
                  }
                >
                  <img src={arrowImg} alt='arrow' />
                </span>
              </span>
            </button>
          </>
        ) : (
          <>KONTAKT</>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default TransportInfoModal
