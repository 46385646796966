import { Link, NavLink, useNavigate } from "react-router-dom"
import classes from "./Header.module.scss"
import { useState } from "react"
import "../../assets/scss/HamburgerNav.scss"
import logoImg from "../../assets/images/logos/logo_2.png"
import emailImg from "../../assets/images/icons/message.png"
import phoneImg from "../../assets/images/icons/telephone.png"
import locationImg from "../../assets/images/icons/location.png"
import arrowImg from "../../assets/images/icons/arrow-right.png"
import FormModal from "../../components/layout/FormModal"
import LanguageSelector from "./LanguageSelector"
import MobileLanguageSelector from "./MobileLanguageSelector"
import Translated from "../../context/state"
/**
 * Header layout component of B2C.
 *
 * @author Aleksandar Ječmenić <jecmenic.aleksandar@croonus.com>
 */
const Header = ({ navBarClass, className, page, sticky }) => {
  const navigate = useNavigate()
  // Shows mobile hamburger div
  const [showMobileDiv, setShowMobileDiv] = useState(false)
  const onMobileDivShow = () => {
    if (!showMobileDiv) document.body.style.overflow = "hidden"
    else document.body.style.overflow = "scroll"
    setShowMobileDiv((prev) => !prev)
  }
  const [showForm, setShowForm] = useState(false)
  return (
    <>
      <FormModal openModal={showForm} handleClose={() => setShowForm(false)} />
      <div
        className={
          showMobileDiv
            ? `${classes["mobile-header-div-active"]} ${classes["mobile-header-div"]}`
            : `${classes["mobile-header-div-deactive"]} ${classes["mobile-header-div"]}`
        }
      >
        {/*     TODO:ENGLISH
        <button className={classes["nav-button-mobile"] + " button-primary"}>
          <span className={"button-span-1"}>English</span>
          <span className={classes["globe-span"] + " button-span-2 bs2-30"}>
            <img src={globeImg} alt="earth" />
          </span>
        </button> */}
        <div className={classes["mail-phone-mobile-container"]}>
          <span className={classes["mail-phone-mobile"]}>
            <span>office@steftrans.com</span>
          </span>
          <span className={classes["mail-phone-mobile"]}>
            <span>+381 32 322 322</span>
          </span>
        </div>

        <MobileLanguageSelector options={["English", "Srpski", "Deutsch"]} />
        <button
          className={classes["nav-button-mobile"] + " button-primary"}
          onClick={() => setShowForm(true)}
        >
          <span className={"button-span-1"}>
            <Translated Key="HEADER_request_for_offer" />
          </span>
          <span className={"button-span-2 bs2-30"}>
            <span className={classes["quote-arrow"] + " arrow-right-black"}>
              <img src={arrowImg} alt="arrow" />
            </span>
          </span>
        </button>
        <ul className={classes["mobile-header-list"]}>
          <li
            onClick={() => {
              navigate("/")
              onMobileDivShow()
            }}
          >
            <Translated Key="HEADER_homepage" />
          </li>
          <li
            onClick={() => {
              navigate("/o-nama")
              onMobileDivShow()
            }}
          >
            <Translated Key="HEADER_about_us" />
          </li>
          <li
            onClick={() => {
              navigate("/medjunarodne-selidbe")
              onMobileDivShow()
            }}
          >
            <Translated Key="HEADER_services" />
          </li>
          <li
            onClick={() => {
              onMobileDivShow()
            }}
          >
            <a
              href='
                          href="https://mobileweb.cvs-mobile.com/"'
            >
              <Translated Key="HEADER_track_your_cargo" />
            </a>
          </li>
          <li
            onClick={() => {
              navigate("/aktuelno")
              onMobileDivShow()
            }}
          >
            <Translated Key="HEADER_news" />
          </li>
          <li
            onClick={() => {
              navigate("/reference")
              onMobileDivShow()
            }}
          >
            <Translated Key="HEADER_references" />
          </li>
          <li
            onClick={() => {
              navigate("/kontakt")
              onMobileDivShow()
            }}
          >
            <Translated Key="HEADER_contact" />
          </li>
        </ul>
      </div>
      {!sticky ? (
        <header
          className={
            classes["header-container"] +
            " " +
            (showMobileDiv && classes["header-container-active"])
          }
        >
          <div className={classes["header-top"]}>
            <LanguageSelector options={["English", "Srpski", "Deutsch"]} />
            <div className={classes["top-nav-links"]}>
              <span className={classes["mail-phone"]}>
                <img alt="email" src={emailImg} />
                <span>office@steftrans.com</span>
              </span>
              <span className={classes["mail-phone"]}>
                <img alt="email" src={phoneImg} />
                <span>+381 32 322 322</span>
              </span>
              <Link to="/kontakt/#mapRel">
                <img alt="email" src={locationImg} />
                <span>
                  <Translated Key="HEADER_location" />
                </span>
              </Link>
            </div>
          </div>
          <div className={classes["header-bottom"]}>
            <div
              className={classes["header-bottom-img-container"]}
              onClick={() => navigate("/")}
            >
              <img className={"h-100"} alt="logo" src={logoImg} />
            </div>

            <div
              id="nav-icon"
              className={
                showMobileDiv
                  ? ` ${classes["nav-icon"]} open ${classes["nav-icon-active"]}`
                  : classes["nav-icon"]
              }
              onClick={onMobileDivShow}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className={classes["nav-links-container"]}>
              <ul>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? classes["active-link"] : ""
                    }
                    to="/"
                  >
                    <Translated Key="HEADER_homepage" />
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? classes["active-link"] : ""
                    }
                    to="/o-nama"
                  >
                    <Translated Key="HEADER_about_us" />
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? classes["active-link"] : ""
                    }
                    to="/medjunarodne-selidbe"
                  >
                    <Translated Key="HEADER_services" />
                  </NavLink>
                </li>
                <li>
                  <a href="https://mobileweb.cvs-mobile.com/">
                    <Translated Key="HEADER_track_your_cargo" />
                  </a>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? classes["active-link"] : ""
                    }
                    to="/aktuelno"
                  >
                    <Translated Key="HEADER_news" />
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? classes["active-link"] : ""
                    }
                    to="/reference"
                  >
                    <Translated Key="HEADER_references" />
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? classes["active-link"] : ""
                    }
                    to="/kontakt"
                  >
                    <Translated Key="HEADER_contact" />
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className={classes["request-quote"]}>
              <button
                className={classes["request-button"] + " button-primary"}
                onClick={() => setShowForm(true)}
              >
                <span className={"button-span-1"}>
                  <Translated Key="HEADER_request_for_offer" />
                </span>
                <span className={"button-span-2 bs2-30"}>
                  <span
                    className={classes["quote-arrow"] + " arrow-right-black"}
                  >
                    <img src={arrowImg} alt="arrow" />
                  </span>
                </span>
              </button>
            </div>
          </div>
        </header>
      ) : (
        <header className={classes["header-container-sticky"]}>
          <div className={classes["sticky-padding"]}>
            <div className={classes["header-bottom"]}>
              <div
                className={classes["header-bottom-img-container"]}
                onClick={() => navigate("/")}
              >
                <img className={"h-100"} alt="logo" src={logoImg} />
              </div>

              <div
                id="nav-icon"
                className={
                  showMobileDiv
                    ? ` ${classes["nav-icon"]} open ${classes["nav-icon-active"]}`
                    : classes["nav-icon"]
                }
                onClick={onMobileDivShow}
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className={classes["nav-links-container"]}>
                <ul>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? classes["active-link"] : ""
                      }
                      to="/"
                    >
                      <Translated Key="HEADER_homepage" />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? classes["active-link"] : ""
                      }
                      to="/o-nama"
                    >
                      <Translated Key="HEADER_about_us" />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? classes["active-link"] : ""
                      }
                      to="/medjunarodne-selidbe"
                    >
                      <Translated Key="HEADER_services" />
                    </NavLink>
                  </li>
                  <li>
                    <a href="https://mobileweb.cvs-mobile.com/">
                      <Translated Key="HEADER_track_your_cargo" />
                    </a>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? classes["active-link"] : ""
                      }
                      to="/aktuelno"
                    >
                      <Translated Key="HEADER_news" />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? classes["active-link"] : ""
                      }
                      to="/reference"
                    >
                      <Translated Key="HEADER_references" />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? classes["active-link"] : ""
                      }
                      to="/kontakt"
                    >
                      <Translated Key="HEADER_contact" />
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className={classes["request-quote"]}>
                <button
                  className={classes["request-button"] + " button-primary"}
                  onClick={() => setShowForm(true)}
                >
                  <span className={"button-span-1"}>
                    <Translated Key="HEADER_request_for_offer" />
                  </span>
                  <span className={"button-span-2 bs2-30"}>
                    <span
                      className={classes["quote-arrow"] + " arrow-right-black"}
                    >
                      <img src={arrowImg} alt="arrow" />
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </header>
      )}
    </>
  )
}

export default Header
